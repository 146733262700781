import React from "react";
import Text from "@src/ui/text";
import { Card } from "@src/ui/card";
import { cn } from "@src/ui/lib/utils";
import Link from "next/link";
import { Route } from "nextjs-routes";

type Props = {
  title: string;
  Icon?: React.ElementType;
  className?: string;
  children: React.ReactNode;
  href?: Route;
};

const Widget: React.FC<Props> = ({
  title,
  Icon,
  children,
  className,
  href,
}) => {
  const Body = (
    <Card
      className={cn(
        "flex flex-col gap-6 p-4 h-full",
        href && "hover:bg-gray-1 transition-colors",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        {Icon && <Icon className="text-muted-foreground" />}
        <Text type="P2" fontWeight="SemiBold">
          {title}
        </Text>
      </div>
      {children}
    </Card>
  );
  if (href) {
    return <Link href={href}>{Body}</Link>;
  }
  return Body;
};

export default Widget;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { ContentTagMembershipFragmentDoc, TranslationSetFragmentDoc, CoverImageFragmentDoc } from '../../fragments.generated';
export type InlineEditTagsLibraryItemFragment = { __typename?: 'LibraryItem', id: string, contentTagMemberships: Array<{ __typename?: 'ContentTagMembership', tagId: string, libraryItemId: string }> };

export const InlineEditTagsLibraryItemFragmentDoc = gql`
    fragment InlineEditTagsLibraryItem on LibraryItem {
  id
  contentTagMemberships {
    ...ContentTagMembership
  }
}
    ${ContentTagMembershipFragmentDoc}`;
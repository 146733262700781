import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersNoRoleSheetQueryVariables = Types.Exact<{
  input: Types.GetPeopleInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type UsersNoRoleSheetQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, name: string, createdAt: string, userType: Types.UserType, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> }> } };

export type UsersNoRoleSheetEmployeeFragment = { __typename?: 'Employee', id: number, name: string, createdAt: string, userType: Types.UserType, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> };

export const UsersNoRoleSheetEmployeeFragmentDoc = gql`
    fragment UsersNoRoleSheetEmployee on Employee {
  id
  name
  createdAt
  userType
  locations {
    id
    name
  }
  roles {
    id
    name
  }
}
    `;
export const UsersNoRoleSheetDocument = gql`
    query UsersNoRoleSheet($input: GetPeopleInput!, $pagination: PaginationInput) {
  People(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...UsersNoRoleSheetEmployee
    }
  }
}
    ${UsersNoRoleSheetEmployeeFragmentDoc}`;

/**
 * __useUsersNoRoleSheetQuery__
 *
 * To run a query within a React component, call `useUsersNoRoleSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersNoRoleSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersNoRoleSheetQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersNoRoleSheetQuery(baseOptions: Apollo.QueryHookOptions<UsersNoRoleSheetQuery, UsersNoRoleSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersNoRoleSheetQuery, UsersNoRoleSheetQueryVariables>(UsersNoRoleSheetDocument, options);
      }
export function useUsersNoRoleSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersNoRoleSheetQuery, UsersNoRoleSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersNoRoleSheetQuery, UsersNoRoleSheetQueryVariables>(UsersNoRoleSheetDocument, options);
        }
export type UsersNoRoleSheetQueryHookResult = ReturnType<typeof useUsersNoRoleSheetQuery>;
export type UsersNoRoleSheetLazyQueryHookResult = ReturnType<typeof useUsersNoRoleSheetLazyQuery>;
export type UsersNoRoleSheetQueryResult = Apollo.QueryResult<UsersNoRoleSheetQuery, UsersNoRoleSheetQueryVariables>;
import React from "react";

import { ListPage } from "@src/components/layout/ListPage";
import MyTrainingPageContent from "@src/components/my-training/MyTrainingPageContent";
import MyTrainingPageHeader from "@src/components/my-training/MyTrainingPageHeader";

const MyTrainingListPage: React.FC = () => {
  return (
    <ListPage>
      <MyTrainingPageHeader />
      <MyTrainingPageContent />
    </ListPage>
  );
};

export default MyTrainingListPage;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncompleteAssignmentsSheetEmployeeFragment = { __typename?: 'Employee', id: number, name: string, createdAt: string, userType: Types.UserType, incompleteAssignmentsCount: number, pastDueAssignmentsCount: number, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> };

export type IncompleteAssignmentsSheetQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationInput>;
  input: Types.AdminEmployeesInput;
}>;


export type IncompleteAssignmentsSheetQuery = { __typename?: 'Query', AdminEmployeesWithIncompleteAssignments: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, name: string, createdAt: string, userType: Types.UserType, incompleteAssignmentsCount: number, pastDueAssignmentsCount: number, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> }> } };

export const IncompleteAssignmentsSheetEmployeeFragmentDoc = gql`
    fragment IncompleteAssignmentsSheetEmployee on Employee {
  id
  name
  createdAt
  userType
  locations {
    id
    name
  }
  roles {
    id
    name
  }
  incompleteAssignmentsCount
  pastDueAssignmentsCount
}
    `;
export const IncompleteAssignmentsSheetDocument = gql`
    query IncompleteAssignmentsSheet($pagination: PaginationInput, $input: AdminEmployeesInput!) {
  AdminEmployeesWithIncompleteAssignments(pagination: $pagination, input: $input) {
    totalCount
    objects {
      ...IncompleteAssignmentsSheetEmployee
    }
  }
}
    ${IncompleteAssignmentsSheetEmployeeFragmentDoc}`;

/**
 * __useIncompleteAssignmentsSheetQuery__
 *
 * To run a query within a React component, call `useIncompleteAssignmentsSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncompleteAssignmentsSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncompleteAssignmentsSheetQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncompleteAssignmentsSheetQuery(baseOptions: Apollo.QueryHookOptions<IncompleteAssignmentsSheetQuery, IncompleteAssignmentsSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncompleteAssignmentsSheetQuery, IncompleteAssignmentsSheetQueryVariables>(IncompleteAssignmentsSheetDocument, options);
      }
export function useIncompleteAssignmentsSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncompleteAssignmentsSheetQuery, IncompleteAssignmentsSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncompleteAssignmentsSheetQuery, IncompleteAssignmentsSheetQueryVariables>(IncompleteAssignmentsSheetDocument, options);
        }
export type IncompleteAssignmentsSheetQueryHookResult = ReturnType<typeof useIncompleteAssignmentsSheetQuery>;
export type IncompleteAssignmentsSheetLazyQueryHookResult = ReturnType<typeof useIncompleteAssignmentsSheetLazyQuery>;
export type IncompleteAssignmentsSheetQueryResult = Apollo.QueryResult<IncompleteAssignmentsSheetQuery, IncompleteAssignmentsSheetQueryVariables>;
import Text from "@src/ui/text";
export const HomeSection = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-4">
      <Text type="P1" fontWeight="Medium">
        {title}
      </Text>
      {children}
    </div>
  );
};

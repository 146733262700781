import * as React from "react";
import { IconProps } from "../types";

const StarOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M16.963 6.78594C16.875 6.51494 16.64 6.31694 16.358 6.27594L11.738 5.60494L9.672 1.41794C9.42 0.905936 8.579 0.905936 8.327 1.41794L6.261 5.60394L1.641 6.27494C1.359 6.31594 1.124 6.51394 1.036 6.78494C0.948003 7.05594 1.021 7.35494 1.226 7.55394L4.569 10.8119L3.779 15.4129C3.731 15.6949 3.846 15.9789 4.077 16.1469C4.308 16.3139 4.615 16.3359 4.867 16.2039L8.999 14.0309L13.131 16.2039C13.241 16.2619 13.36 16.2899 13.48 16.2899C13.635 16.2899 13.79 16.2419 13.921 16.1469C14.152 15.9789 14.268 15.6949 14.219 15.4129L13.429 10.8119L16.772 7.55394C16.977 7.35494 17.05 7.05594 16.962 6.78494L16.963 6.78594Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default StarOutlineIcon;

import React from "react";
import { listToFirstItemPlus } from "@src/components/home/utils";
import Text from "@src/ui/text";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";
import IdBadgeOutlineIcon from "@src/ui/icons/18px/id-badge-outline";
import Link from "next/link";

type Props = {
  userId: string;
  userName: string;
  locations: string[];
  roles: string[];
};

const UserNameLocationsRolesTableCell: React.FC<Props> = (props) => {
  const locations = React.useMemo(() => {
    return props.locations.length > 0
      ? listToFirstItemPlus(props.locations)
      : "No locations";
  }, [props.locations]);
  const roles = React.useMemo(() => {
    return props.roles.length > 0
      ? listToFirstItemPlus(props.roles)
      : "No roles";
  }, [props.roles]);
  return (
    <Link
      href={{
        pathname: "/users/[id]",
        query: {
          id: props.userId.toString(),
        },
      }}
    >
      <div className="group flex cursor-pointer flex-row items-center gap-2">
        <div className="flex flex-col">
          <Text type="P2" fontWeight="Medium" className="group-hover:underline">
            {props.userName}
          </Text>
          <div className="flex flex-row gap-2">
            <div className="flex flex-row items-center gap-1">
              <PinsOutlineIcon className="size-3 text-muted-foreground" />
              <Text
                type="P3"
                fontWeight="Medium"
                className="text-muted-foreground"
              >
                {locations}
              </Text>
            </div>
            <Text
              type="P3"
              fontWeight="Medium"
              className="text-muted-foreground"
            >
              ·
            </Text>
            <div className="flex flex-row items-center gap-1">
              <IdBadgeOutlineIcon className="size-3 text-muted-foreground" />
              <Text
                type="P3"
                fontWeight="Medium"
                className="text-muted-foreground"
              >
                {roles}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default UserNameLocationsRolesTableCell;

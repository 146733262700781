import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivityWidgetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActivityWidgetQuery = { __typename?: 'Query', RecentActivity: Array<{ __typename?: 'RecentActivityItem', type: Types.RecentActivityItemType, datetime: string, user: { __typename?: 'Employee', id: number, name: string }, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', en: string } } }> };


export const ActivityWidgetDocument = gql`
    query ActivityWidget {
  RecentActivity {
    user {
      id
      name
    }
    libraryItem {
      id
      name {
        en
      }
      type
    }
    type
    datetime
  }
}
    `;

/**
 * __useActivityWidgetQuery__
 *
 * To run a query within a React component, call `useActivityWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityWidgetQuery(baseOptions?: Apollo.QueryHookOptions<ActivityWidgetQuery, ActivityWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityWidgetQuery, ActivityWidgetQueryVariables>(ActivityWidgetDocument, options);
      }
export function useActivityWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityWidgetQuery, ActivityWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityWidgetQuery, ActivityWidgetQueryVariables>(ActivityWidgetDocument, options);
        }
export type ActivityWidgetQueryHookResult = ReturnType<typeof useActivityWidgetQuery>;
export type ActivityWidgetLazyQueryHookResult = ReturnType<typeof useActivityWidgetLazyQuery>;
export type ActivityWidgetQueryResult = Apollo.QueryResult<ActivityWidgetQuery, ActivityWidgetQueryVariables>;
import { useUserContextState } from "@src/contexts/UserContext";
import Text from "@src/ui/text";
import { getTimeOfDay } from "@src/utils/dates";
import { FC } from "react";

type Props = {
  children: React.ReactNode;
};

const Home: FC<Props> = ({ children }) => {
  const { user } = useUserContextState();
  return (
    <div className="flex w-full flex-col p-[24px]">
      {user?.firstName && (
        <Text type="H2" fontWeight="SemiBold" className="mb-[16px]">
          Good {getTimeOfDay()}, {user?.firstName}
        </Text>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Home;

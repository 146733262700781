import Widget from "./Widget";
import { gql } from "@apollo/client";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import { Skeleton } from "@src/ui/skeleton";
import { useAssignmentCompletion__HomeWidgetQuery } from "./AssignmentsCompletionWidget.generated";
import Text from "@src/ui/text";
import CheckDoubleIcon from "@src/ui/icons/18px/check-double";
import { format, parse, subYears } from "date-fns";
import { useMemo } from "react";
import EmptyWidgetContent from "./EmptyWidgetContent";

const NOW = new Date();

const chartConfig = {
  totalAssignments: {
    label: "Assigned",
  },
  completedAssignments: {
    label: "Completed",
  },
};

const AssignmentsCompletionWidget: React.FC = () => {
  const { data, loading } = useAssignmentCompletion__HomeWidgetQuery({
    variables: {
      input: { since: { value: subYears(NOW, 1.5).toISOString() } },
    },
  });

  const weeksData = useMemo(() => {
    if (!data?.AssignmentCompletion) return [];

    const weekMap = data.AssignmentCompletion.reduce(
      (acc, item) => {
        const weekStart = parse(item.day, "yyyy-MM-dd", new Date());
        weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Set to the start of the week (Sunday)
        const weekKey = format(weekStart, "yyyy-MM-dd");

        if (!acc[weekKey] || new Date(item.day) > new Date(acc[weekKey].day)) {
          acc[weekKey] = item;
        }

        return acc;
      },
      {} as Record<string, (typeof data.AssignmentCompletion)[0]>,
    );

    return Object.values(weekMap);
  }, [data]);

  const totalAssignments = Math.max(
    ...(weeksData.map((item) => item.totalAssignments) || []),
  );
  const completedAssignments = Math.max(
    ...(weeksData.map((item) => item.completedAssignments) || []),
  );
  const formatTooltipLabel = (dateString: string) => {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    return format(date, "MMM yy");
  };

  const formattedData = weeksData.map?.((item) => ({
    ...item,
    tooltipLabel: formatTooltipLabel(item.tooltipLabel),
  }));

  // Compute first and last tooltip labels for the X-axis ticks
  const ticks =
    formattedData && formattedData.length > 0
      ? [
          formattedData[0].tooltipLabel,
          formattedData[formattedData.length - 1].tooltipLabel,
        ]
      : [];

  const showEmptyState = totalAssignments == -Infinity;
  if (!loading && showEmptyState) {
    return (
      <Widget title="Completed Assignments vs total" Icon={CheckDoubleIcon}>
        <EmptyWidgetContent
          illustration={
            <CheckDoubleIcon className="size-8 text-muted-foreground" />
          }
          description="You'll use this to understand assignment completion trends"
        />
      </Widget>
    );
  }
  return (
    <Widget title="Completed Assignments vs total" Icon={CheckDoubleIcon}>
      {loading && <Skeleton className="min-h-[278px] w-full rounded-xl" />}
      {!loading && !showEmptyState && (
        <div className="flex h-full flex-row items-end justify-between">
          <div className="mr-4 flex flex-col self-end pb-2">
            <Text type="H1" fontWeight="SemiBold">
              {completedAssignments?.toLocaleString()}
            </Text>
            <Text type="P3" fontWeight="Medium" className="text-gray-7">
              vs{" "}
              <span className="text-gray-10">
                {totalAssignments?.toLocaleString()}
              </span>{" "}
              Assigned
            </Text>
          </div>
          <ChartContainer
            config={chartConfig}
            className="aspect-auto size-full"
          >
            <LineChart accessibilityLayer data={formattedData || []}>
              <CartesianGrid
                vertical={true}
                horizontal={false}
                strokeDasharray="6 6"
              />
              <XAxis
                dataKey="axisLabel"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                xAxisId={0}
                height={0}
              />
              <XAxis
                dataKey="tooltipLabel"
                ticks={ticks}
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                allowDuplicatedCategory={false}
                type="category"
                xAxisId={1}
                interval="preserveStartEnd"
                orientation="bottom"
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent />}
                isAnimationActive={false}
              />
              <Line
                dataKey="totalAssignments"
                type="monotone"
                strokeWidth={2}
                dot={false}
                stroke="#4778F5"
                isAnimationActive={false}
              />
              <Line
                dataKey="completedAssignments"
                type="monotone"
                strokeWidth={2}
                dot={false}
                stroke="#40D986"
                isAnimationActive={false}
              />
            </LineChart>
          </ChartContainer>
        </div>
      )}
    </Widget>
  );
};

gql`
  query AssignmentCompletion__HomeWidget($input: AssignmentCompletionInput!) {
    AssignmentCompletion(input: $input) {
      libraryItemId
      day
      totalAssignments
      completedAssignments
      axisLabel
      tooltipLabel
    }
  }
`;

export default AssignmentsCompletionWidget;

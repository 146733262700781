import React from "react";
import { ListPageHeader, ListPageTitle } from "@src/components/layout/ListPage";
import { useTranslation } from "@src/utils/translationSets";
import useLanguage from "@src/hooks/useLanguage";

const MY_TRAINING_ENGLISH = "My Training";

const MyTrainingPageHeader: React.FC = () => {
  const lang = useLanguage();
  const myTrainingTranslation = useTranslation(
    MY_TRAINING_ENGLISH,
    lang || "en",
  );
  return (
    <ListPageHeader>
      <ListPageTitle>
        {myTrainingTranslation.text || MY_TRAINING_ENGLISH}
      </ListPageTitle>
    </ListPageHeader>
  );
};

export default MyTrainingPageHeader;

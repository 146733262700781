import React, { FC, useMemo, useState } from "react";
import { useAddUsersToRolesMutation } from "@components/modals/operations.generated";
import { useTracking } from "@hooks/useTracking";
import { AddUsersToRolesInput } from "@src/types.generated";
import { MinimalEmployeeFragment } from "../ui/operations.generated";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@ui/dialog";
import { Button } from "@ui/button";
import Text from "@ui/text";
import RoleMultiSelect from "@src/components/users/RoleMultiSelect";
import UserMultiSelect from "@src/components/users/UserMultiSelect";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
  refetchQueries?: string[];
  children?: React.ReactNode;
  initialUsers?: MinimalEmployeeFragment[];
};

const BulkAddUserRolesDialog: FC<Props> = ({
  open,
  onOpenChange,
  onSuccess,
  refetchQueries = [],
  children,
  initialUsers = [],
}) => {
  const [roleIds, setRoleIds] = useState<number[]>([]);
  const [users, setUsers] = useState<MinimalEmployeeFragment[]>(initialUsers);
  const userIds = useMemo(() => users.map((e) => e.id), [users]);
  const addTraineesToGroupsInput: AddUsersToRolesInput = {
    roleIds: roleIds,
    userIds,
  };
  const { trackEvent } = useTracking();
  const [addTraineesToRoles, { loading }] = useAddUsersToRolesMutation({
    variables: {
      input: addTraineesToGroupsInput,
    },
    refetchQueries,
    onCompleted: (data) => {
      if (data.addUsersToRoles.success) {
        if (onSuccess) {
          onSuccess();
        }
        trackEvent("users_added_to_role", addTraineesToGroupsInput);
        onOpenChange(false);
      }
    },
  });
  const handleSave = () => {
    if (roleIds.length > 0 && userIds.length > 0) {
      addTraineesToRoles();
    }
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add roles</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="mb-4">
            <Text type="P2" fontWeight="SemiBold" className="mb-2">
              Users
            </Text>
            <UserMultiSelect
              setEmployees={setUsers}
              defaultUserIds={userIds.map((id) => id.toString())}
              placeholder="Select users..."
            />
          </div>
          <div>
            <Text type="P2" fontWeight="SemiBold" className="mb-2">
              Roles
            </Text>
            <RoleMultiSelect
              roleIds={roleIds}
              setRoleIds={setRoleIds}
              placeholder="Select roles..."
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={loading || roleIds.length === 0 || userIds.length === 0}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BulkAddUserRolesDialog;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TotalActiveUsersWidgetQueryVariables = Types.Exact<{
  input: Types.UserActivityInput;
}>;


export type TotalActiveUsersWidgetQuery = { __typename?: 'Query', UserActivity: Array<{ __typename?: 'UserActivityWeek', week?: string | null, totalUsers?: number | null, activeUsers?: number | null, label: string }> };


export const TotalActiveUsersWidgetDocument = gql`
    query TotalActiveUsersWidget($input: UserActivityInput!) {
  UserActivity(input: $input) {
    week
    totalUsers
    activeUsers
    label
  }
}
    `;

/**
 * __useTotalActiveUsersWidgetQuery__
 *
 * To run a query within a React component, call `useTotalActiveUsersWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalActiveUsersWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalActiveUsersWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTotalActiveUsersWidgetQuery(baseOptions: Apollo.QueryHookOptions<TotalActiveUsersWidgetQuery, TotalActiveUsersWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalActiveUsersWidgetQuery, TotalActiveUsersWidgetQueryVariables>(TotalActiveUsersWidgetDocument, options);
      }
export function useTotalActiveUsersWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalActiveUsersWidgetQuery, TotalActiveUsersWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalActiveUsersWidgetQuery, TotalActiveUsersWidgetQueryVariables>(TotalActiveUsersWidgetDocument, options);
        }
export type TotalActiveUsersWidgetQueryHookResult = ReturnType<typeof useTotalActiveUsersWidgetQuery>;
export type TotalActiveUsersWidgetLazyQueryHookResult = ReturnType<typeof useTotalActiveUsersWidgetLazyQuery>;
export type TotalActiveUsersWidgetQueryResult = Apollo.QueryResult<TotalActiveUsersWidgetQuery, TotalActiveUsersWidgetQueryVariables>;
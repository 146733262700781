import React from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@src/ui/sheet";

import { gql } from "@apollo/client";

import PaginatedTable from "@src/deprecatedDesignSystem/components/table/PaginatedTable";
import {
  UsersNoRoleSheetEmployeeFragment,
  useUsersNoRoleSheetLazyQuery,
  useUsersNoRoleSheetQuery,
} from "@src/components/home/UsersNoRoleSheet.generated";
import useActionPermissions from "@src/hooks/useActionPermissions";
import {
  GetPeopleInput,
  GraphqlOperations,
  UserType,
} from "@src/types.generated";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import { Button } from "@ui/button";
import CirclePlusOutlineIcon from "@src/ui/icons/18px/circle-plus-outline";
import BulkAddUserRolesDialog from "@src/components/home/BulkAddUserRolesDialog";
import UserNameLocationsRolesTableCell from "@src/components/home/UserNameLocationsRolesTableCell";

type Props = {
  children: React.ReactNode;
};

type Column = "name" | "actions";
const exportColumns = ["name", "actions"] as const;
type ExportColumn = (typeof exportColumns)[number];

const UsersNoRoleSheet: React.FC<Props> = (props) => {
  const { userActionPermissions } = useActionPermissions();
  const [selectedUsers, setSelectedUsers] = React.useState<
    UsersNoRoleSheetEmployeeFragment[]
  >([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const input: GetPeopleInput = {
    hasRole: { value: false },
    userTypes: { value: [UserType.Employee, UserType.Manager] },
  };

  const handleOpenDialog = (users: UsersNoRoleSheetEmployeeFragment[]) => {
    setSelectedUsers(users);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedUsers([]);
  };

  return (
    <Sheet>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Users without a role</SheetTitle>
          <SheetDescription>
            Add roles for all users in this list
          </SheetDescription>
        </SheetHeader>
        <PaginatedTable<
          UsersNoRoleSheetEmployeeFragment,
          Column,
          typeof useUsersNoRoleSheetQuery,
          typeof useUsersNoRoleSheetLazyQuery,
          ExportColumn
        >
          title={undefined}
          input={input}
          allItemsCountInput={undefined}
          queryHook={useUsersNoRoleSheetQuery}
          lazyQueryHook={useUsersNoRoleSheetLazyQuery}
          getItems={(x) => x?.People.objects ?? []}
          getTotalItemCount={(x) => x?.People.totalCount ?? 0}
          itemId={(x) => x.id}
          defaultSort={{ column: "name", descending: true }}
          allColumns={["name", "actions"]}
          columnTitles={{ name: "User", actions: "" }}
          rowHeight={52}
          renderFns={{
            name: (x) => (
              <UserNameLocationsRolesTableCell
                userId={x.id.toString()}
                userName={x.name}
                locations={x.locations.map((loc) => loc.name)}
                roles={x.roles.map((role) => role.name)}
              />
            ),
            actions: (x) => (
              <div className="flex flex-1 items-center justify-end gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleOpenDialog([x])}
                >
                  <CirclePlusOutlineIcon className="size-4 text-muted-foreground" />
                  Add roles
                </Button>
              </div>
            ),
          }}
          multiselect={{
            checkboxColumnWidth: 32,
            renderAvatarFn: (x) => <PersonAvatar person={x} size="32px" />,
            actions: (selectedItems) => [
              {
                key: "addRoles",
                icon: "plus-circle",
                label: "Add roles",
                onClick: () => handleOpenDialog(selectedItems),
                hasPermission: Boolean(userActionPermissions?.editTrainees),
              },
            ],
          }}
        />
        {selectedUsers.length > 0 && (
          <BulkAddUserRolesDialog
            initialUsers={selectedUsers}
            open={isDialogOpen}
            onOpenChange={handleCloseDialog}
            refetchQueries={[
              GraphqlOperations.Query.UsersNoRoleSheet,
              GraphqlOperations.Query.UsersNoRoleWidgetCount,
            ]}
          />
        )}
      </SheetContent>
    </Sheet>
  );
};

gql`
  query UsersNoRoleSheet(
    $input: GetPeopleInput!
    $pagination: PaginationInput
  ) {
    People(input: $input, pagination: $pagination) {
      totalCount
      objects {
        ...UsersNoRoleSheetEmployee
      }
    }
  }
  fragment UsersNoRoleSheetEmployee on Employee {
    id
    name
    createdAt
    userType
    locations {
      id
      name
    }
    roles {
      id
      name
    }
  }
`;

export default UsersNoRoleSheet;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignmentCompletion__HomeWidgetQueryVariables = Types.Exact<{
  input: Types.AssignmentCompletionInput;
}>;


export type AssignmentCompletion__HomeWidgetQuery = { __typename?: 'Query', AssignmentCompletion: Array<{ __typename?: 'AssignmentCompletionDay', libraryItemId?: string | null, day: string, totalAssignments: number, completedAssignments: number, axisLabel: string, tooltipLabel: string }> };


export const AssignmentCompletion__HomeWidgetDocument = gql`
    query AssignmentCompletion__HomeWidget($input: AssignmentCompletionInput!) {
  AssignmentCompletion(input: $input) {
    libraryItemId
    day
    totalAssignments
    completedAssignments
    axisLabel
    tooltipLabel
  }
}
    `;

/**
 * __useAssignmentCompletion__HomeWidgetQuery__
 *
 * To run a query within a React component, call `useAssignmentCompletion__HomeWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentCompletion__HomeWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentCompletion__HomeWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignmentCompletion__HomeWidgetQuery(baseOptions: Apollo.QueryHookOptions<AssignmentCompletion__HomeWidgetQuery, AssignmentCompletion__HomeWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignmentCompletion__HomeWidgetQuery, AssignmentCompletion__HomeWidgetQueryVariables>(AssignmentCompletion__HomeWidgetDocument, options);
      }
export function useAssignmentCompletion__HomeWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignmentCompletion__HomeWidgetQuery, AssignmentCompletion__HomeWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignmentCompletion__HomeWidgetQuery, AssignmentCompletion__HomeWidgetQueryVariables>(AssignmentCompletion__HomeWidgetDocument, options);
        }
export type AssignmentCompletion__HomeWidgetQueryHookResult = ReturnType<typeof useAssignmentCompletion__HomeWidgetQuery>;
export type AssignmentCompletion__HomeWidgetLazyQueryHookResult = ReturnType<typeof useAssignmentCompletion__HomeWidgetLazyQuery>;
export type AssignmentCompletion__HomeWidgetQueryResult = Apollo.QueryResult<AssignmentCompletion__HomeWidgetQuery, AssignmentCompletion__HomeWidgetQueryVariables>;
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvgTrainingFeedbackWidgetQueryVariables = Types.Exact<{
  input: Types.AvgTrainingFeedbackInput;
}>;


export type AvgTrainingFeedbackWidgetQuery = { __typename?: 'Query', AvgTrainingFeedback: Array<{ __typename?: 'AvgTrainingFeedbackWeek', week?: string | null, average: number, label: string }> };


export const AvgTrainingFeedbackWidgetDocument = gql`
    query AvgTrainingFeedbackWidget($input: AvgTrainingFeedbackInput!) {
  AvgTrainingFeedback(input: $input) {
    week
    average
    label
  }
}
    `;

/**
 * __useAvgTrainingFeedbackWidgetQuery__
 *
 * To run a query within a React component, call `useAvgTrainingFeedbackWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvgTrainingFeedbackWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvgTrainingFeedbackWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvgTrainingFeedbackWidgetQuery(baseOptions: Apollo.QueryHookOptions<AvgTrainingFeedbackWidgetQuery, AvgTrainingFeedbackWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvgTrainingFeedbackWidgetQuery, AvgTrainingFeedbackWidgetQueryVariables>(AvgTrainingFeedbackWidgetDocument, options);
      }
export function useAvgTrainingFeedbackWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvgTrainingFeedbackWidgetQuery, AvgTrainingFeedbackWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvgTrainingFeedbackWidgetQuery, AvgTrainingFeedbackWidgetQueryVariables>(AvgTrainingFeedbackWidgetDocument, options);
        }
export type AvgTrainingFeedbackWidgetQueryHookResult = ReturnType<typeof useAvgTrainingFeedbackWidgetQuery>;
export type AvgTrainingFeedbackWidgetLazyQueryHookResult = ReturnType<typeof useAvgTrainingFeedbackWidgetLazyQuery>;
export type AvgTrainingFeedbackWidgetQueryResult = Apollo.QueryResult<AvgTrainingFeedbackWidgetQuery, AvgTrainingFeedbackWidgetQueryVariables>;
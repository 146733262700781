import * as React from "react";
import { IconProps } from "../types";

const PieChartOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M15.602 11.9999C14.461 14.5069 11.934 16.2499 9 16.2499C4.996 16.2499 1.75 13.0039 1.75 8.99995C1.75 6.06595 3.493 3.53895 6 2.39795"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.25 9C16.25 4.996 13.004 1.75 9 1.75V9H16.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default PieChartOutlineIcon;

import Widget from "./Widget";
import { Skeleton } from "@src/ui/skeleton";
import { gql } from "@apollo/client";
import { useAvgTrainingFeedbackWidgetQuery } from "./AvgTrainingFeedbackWidget.generated";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import StarOutlineIcon from "@src/ui/icons/18px/star-outline";
import { useMemo } from "react";
import Text from "@src/ui/text";
import { subYears } from "date-fns";
import EmptyWidgetContent from "./EmptyWidgetContent";

const NOW = new Date();

const chartConfig = {
  average: {
    label: "Average",
  },
};

const AvgTrainingFeedbackWidget: React.FC = () => {
  const { loading, data } = useAvgTrainingFeedbackWidgetQuery({
    variables: {
      input: { since: { value: subYears(NOW, 1.5).toISOString() } },
    },
  });
  const formattedData = useMemo(() => data?.AvgTrainingFeedback || [], [data]);
  const average = useMemo(
    () =>
      (
        formattedData.reduce((acc, curr) => acc + curr.average, 0) /
        (formattedData.length || 1)
      ).toFixed(1),
    [formattedData],
  );
  const ticks =
    formattedData && formattedData.length > 0
      ? [formattedData[0].label, formattedData[formattedData.length - 1].label]
      : [];

  if (!loading && average === "0.0") {
    return (
      <Widget title="Average Training Feedback" Icon={StarOutlineIcon}>
        {!loading && average === "0.0" && (
          <EmptyWidgetContent
            illustration={
              <StarOutlineIcon className="size-8 text-muted-foreground" />
            }
            description="You'll be able to identify feedback trends here"
          />
        )}
      </Widget>
    );
  }
  return (
    <Widget
      title="Average Training Feedback"
      Icon={StarOutlineIcon}
      href={{ pathname: "/reporting/course-feedback" }}
    >
      {!loading && average !== "0.0" && (
        <div>
          {loading && <Skeleton className="h-10 w-20 rounded-md" />}
          {!loading && (
            <Text type="H1" fontWeight="SemiBold">
              ⭐️ {average} / 5
            </Text>
          )}
          {loading && <Skeleton className="mt-5 h-[250px] w-full rounded-xl" />}
          {!loading && (
            <ChartContainer
              config={chartConfig}
              className="mt-5 h-[250px] w-full"
            >
              <LineChart
                accessibilityLayer
                data={data?.AvgTrainingFeedback || []}
              >
                <CartesianGrid
                  vertical={true}
                  horizontal={false}
                  strokeDasharray="6 6"
                />
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  xAxisId={0}
                  height={0}
                />
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  ticks={ticks}
                  allowDuplicatedCategory={false}
                  type="category"
                  xAxisId={1}
                  interval="preserveStartEnd"
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent />}
                  isAnimationActive={false}
                />
                <Line
                  dataKey="average"
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  stroke="#4778F5"
                  isAnimationActive={false}
                />
              </LineChart>
            </ChartContainer>
          )}
        </div>
      )}
    </Widget>
  );
};

gql`
  query AvgTrainingFeedbackWidget($input: AvgTrainingFeedbackInput!) {
    AvgTrainingFeedback(input: $input) {
      week
      average
      label
    }
  }
`;

export default AvgTrainingFeedbackWidget;

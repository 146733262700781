import Widget from "./Widget";
import { gql } from "@apollo/client";
import { Skeleton } from "@src/ui/skeleton";
import { useAdminRecentlyEditedPathsQuery } from "./RecentlyEditedModulesWidget.generated";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import Text from "@ui/text";
import { Button } from "@src/ui/button";
import Link from "next/link";

const RecentlyEditedModulesWidget: React.FC = () => {
  const { data, loading } = useAdminRecentlyEditedPathsQuery();

  if (!loading && data?.AdminLibrary.objects.length === 0) {
    return null;
  }
  return (
    <Widget title="Recently Edited Modules">
      {loading ? (
        <Skeleton className="h-[36px] w-full rounded-xl" />
      ) : (
        <div
          className="flex flex-row flex-nowrap gap-4 overflow-x-auto"
          style={{
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE 10+
          }}
        >
          {data?.AdminLibrary.objects.map((obj) => (
            <Link
              href={{
                pathname: "/library/library-item/[id]",
                query: { id: obj.path?.libraryItem.id as string },
              }}
              key={obj.id}
            >
              <Button variant="ghost" className="px-2">
                <div key={obj.id} className="flex flex-row items-center gap-2">
                  <ContentAvatar
                    size={"24px"}
                    contentType={"Module"}
                    style={{ borderRadius: 8 }}
                    defaultBackgroundHashKey={
                      obj.path?.libraryItem.id as string
                    }
                    coverImage={obj.path?.libraryItem.coverImage}
                  />
                  <Text type="P3" fontWeight="Medium">
                    {obj.name.en || "Untitled Module"}
                  </Text>
                </div>
              </Button>
            </Link>
          ))}
        </div>
      )}
    </Widget>
  );
};

gql`
  query AdminRecentlyEditedPaths {
    AdminLibrary(
      input: {
        filters: { itemType: { value: PATH } }
        sort: { column: "lastEdited", descending: true, nullsLast: true }
      }
      pagination: { limit: 10 }
    ) {
      objects {
        id
        name {
          en
        }
        lastEditedAt
        path {
          id
          libraryItem {
            id
            name {
              en
            }
            coverImage {
              ...LibraryItemDetailPageCoverImage_CoverImage
            }
          }
        }
      }
    }
  }
`;

export default RecentlyEditedModulesWidget;

import { FC } from "react";
import Home from "./Home";
import { HomeSection } from "./HomeSection";
import OverviewOfYourLocationsWidgets from "@src/components/home/OverviewOfYourLocationsWidgets";
import LocationAssignmentLeaderboardWidget from "./LocationAssignmentLeaderboardWidget";
import ActiveLanguagesWidget from "./ActiveLanguagesWidget";
import ActivityWidget from "./ActivityWidget";

const ManagerHome: FC = () => {
  return (
    <Home>
      <div className="flex flex-col gap-6">
        <OverviewOfYourLocationsWidgets />
        <HomeSection title="Usage">
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
            <ActivityWidget />
            <ActiveLanguagesWidget />
          </div>
        </HomeSection>
        <HomeSection title="Assignments">
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <LocationAssignmentLeaderboardWidget />
          </div>
        </HomeSection>
      </div>
    </Home>
  );
};

export default ManagerHome;

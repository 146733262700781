import Widget from "./Widget";
import { gql } from "@apollo/client";
import { useTotalActiveUsersWidgetQuery } from "./TotalActiveUsersWidget.generated";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import { Skeleton } from "@src/ui/skeleton";
import { useMemo } from "react";
import LineChartIcon from "@src/ui/icons/18px/line-chart";
import { subYears } from "date-fns";

const NOW = new Date();

const chartConfig = {
  totalUsers: {
    label: "Total Users",
  },
  activeUsers: {
    label: "Active Users",
  },
};

const TotalActiveUsersWidget: React.FC = () => {
  const { data, loading } = useTotalActiveUsersWidgetQuery({
    variables: {
      input: {
        since: { value: subYears(NOW, 1.5).toISOString() },
      },
    },
  });

  const totalUsers = useMemo(() => data?.UserActivity || [], [data]);

  const ticks =
    totalUsers && totalUsers.length > 0
      ? [totalUsers[0].label, totalUsers[totalUsers.length - 1].label]
      : [];

  return (
    <Widget title="Total vs Active Users" Icon={LineChartIcon}>
      {loading ? (
        <Skeleton className="min-h-[333px] w-full rounded-xl" />
      ) : (
        <>
          <ChartContainer config={chartConfig} className="h-full">
            <LineChart accessibilityLayer data={data?.UserActivity || []}>
              <CartesianGrid
                vertical={true}
                horizontal={false}
                strokeDasharray="6 6"
              />
              <XAxis
                dataKey="label"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                xAxisId={0}
                height={0}
              />
              <XAxis
                dataKey="label"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                allowDuplicatedCategory={false}
                type="category"
                xAxisId={1}
                ticks={ticks}
                interval="preserveStartEnd"
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent />}
                isAnimationActive={false}
              />
              <Line
                dataKey="totalUsers"
                type="monotone"
                strokeWidth={2}
                dot={false}
                stroke="#4778F5"
                isAnimationActive={false}
              />
              <Line
                dataKey="activeUsers"
                type="monotone"
                strokeWidth={2}
                dot={false}
                stroke="#40D986"
                isAnimationActive={false}
              />
            </LineChart>
          </ChartContainer>
        </>
      )}
    </Widget>
  );
};

gql`
  query TotalActiveUsersWidget($input: UserActivityInput!) {
    UserActivity(input: $input) {
      week
      totalUsers
      activeUsers
      label
    }
  }
`;

export default TotalActiveUsersWidget;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { CoverImageFragmentDoc, TranslationSetFragmentDoc, MediaFragmentDoc, ContentTagMembershipFragmentDoc, ContentQrCodeFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecentlyAssignedLibraryItem__WidgetFragment = { __typename?: 'RecentlyAssignedLibraryItem', id: string, numAssignedSince: number, numAssignedTotal: number, numCompletedTotal: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, name: { __typename?: 'TranslationSet', en: string } } };

export type RecentlyAssignedLibraryItemsQueryVariables = Types.Exact<{
  since: Types.Scalars['DateTime'];
}>;


export type RecentlyAssignedLibraryItemsQuery = { __typename?: 'Query', RecentlyAssignedLibraryItems: Array<{ __typename?: 'RecentlyAssignedLibraryItem', id: string, numAssignedSince: number, numAssignedTotal: number, numCompletedTotal: number, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, name: { __typename?: 'TranslationSet', en: string } } }> };

export const RecentlyAssignedLibraryItem__WidgetFragmentDoc = gql`
    fragment RecentlyAssignedLibraryItem__Widget on RecentlyAssignedLibraryItem {
  id
  libraryItem {
    id
    coverImage {
      ...CoverImage
    }
    name {
      en
    }
    type
  }
  numAssignedSince
  numAssignedTotal
  numCompletedTotal
}
    ${CoverImageFragmentDoc}`;
export const RecentlyAssignedLibraryItemsDocument = gql`
    query RecentlyAssignedLibraryItems($since: DateTime!) {
  RecentlyAssignedLibraryItems(input: {since: {value: $since}}) {
    ...RecentlyAssignedLibraryItem__Widget
  }
}
    ${RecentlyAssignedLibraryItem__WidgetFragmentDoc}`;

/**
 * __useRecentlyAssignedLibraryItemsQuery__
 *
 * To run a query within a React component, call `useRecentlyAssignedLibraryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyAssignedLibraryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyAssignedLibraryItemsQuery({
 *   variables: {
 *      since: // value for 'since'
 *   },
 * });
 */
export function useRecentlyAssignedLibraryItemsQuery(baseOptions: Apollo.QueryHookOptions<RecentlyAssignedLibraryItemsQuery, RecentlyAssignedLibraryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentlyAssignedLibraryItemsQuery, RecentlyAssignedLibraryItemsQueryVariables>(RecentlyAssignedLibraryItemsDocument, options);
      }
export function useRecentlyAssignedLibraryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentlyAssignedLibraryItemsQuery, RecentlyAssignedLibraryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentlyAssignedLibraryItemsQuery, RecentlyAssignedLibraryItemsQueryVariables>(RecentlyAssignedLibraryItemsDocument, options);
        }
export type RecentlyAssignedLibraryItemsQueryHookResult = ReturnType<typeof useRecentlyAssignedLibraryItemsQuery>;
export type RecentlyAssignedLibraryItemsLazyQueryHookResult = ReturnType<typeof useRecentlyAssignedLibraryItemsLazyQuery>;
export type RecentlyAssignedLibraryItemsQueryResult = Apollo.QueryResult<RecentlyAssignedLibraryItemsQuery, RecentlyAssignedLibraryItemsQueryVariables>;
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TotalLocationsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TotalLocationsCountQuery = { __typename?: 'Query', Locations: { __typename?: 'Locations', totalCount: number } };


export const TotalLocationsCountDocument = gql`
    query TotalLocationsCount {
  Locations {
    totalCount
  }
}
    `;

/**
 * __useTotalLocationsCountQuery__
 *
 * To run a query within a React component, call `useTotalLocationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalLocationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalLocationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalLocationsCountQuery(baseOptions?: Apollo.QueryHookOptions<TotalLocationsCountQuery, TotalLocationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalLocationsCountQuery, TotalLocationsCountQueryVariables>(TotalLocationsCountDocument, options);
      }
export function useTotalLocationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalLocationsCountQuery, TotalLocationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalLocationsCountQuery, TotalLocationsCountQueryVariables>(TotalLocationsCountDocument, options);
        }
export type TotalLocationsCountQueryHookResult = ReturnType<typeof useTotalLocationsCountQuery>;
export type TotalLocationsCountLazyQueryHookResult = ReturnType<typeof useTotalLocationsCountLazyQuery>;
export type TotalLocationsCountQueryResult = Apollo.QueryResult<TotalLocationsCountQuery, TotalLocationsCountQueryVariables>;
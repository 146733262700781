import * as React from "react";
import { IconProps } from "../types";

const LineChartIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M2.74999 11.5C2.55799 11.5 2.36599 11.427 2.21999 11.28C1.92699 10.987 1.92699 10.512 2.21999 10.219L5.86599 6.57302C6.35199 6.08602 7.14699 6.08602 7.63399 6.57302L10.75 9.68902L14.72 5.71902C15.013 5.42602 15.488 5.42602 15.781 5.71902C16.074 6.01202 16.074 6.48702 15.781 6.78002L11.635 10.926C11.149 11.413 10.354 11.413 9.86699 10.926L6.75099 7.81002L3.28099 11.28C3.13499 11.426 2.94199 11.5 2.74999 11.5Z"
          fill={color}
        />
        <path
          d="M15.25 15.5H4.75C3.233 15.5 2 14.267 2 12.75V2.75C2 2.336 2.336 2 2.75 2C3.164 2 3.5 2.336 3.5 2.75V12.75C3.5 13.439 4.061 14 4.75 14H15.25C15.664 14 16 14.336 16 14.75C16 15.164 15.664 15.5 15.25 15.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default LineChartIcon;

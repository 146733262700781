import Widget from "./Widget";
import { gql } from "@apollo/client";
import {
  useActiveLanguagesWidgetQuery,
  LanguageCountFragment,
} from "./ActiveLanguagesWidget.generated";
import { Skeleton } from "@src/ui/skeleton";
import { Pie, PieChart } from "recharts";
import { useRouter } from "next/router";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import { langCodeToEnglishName } from "@utils/copy";
import { useMemo, useState, useCallback } from "react";
import Text from "@src/ui/text";

const CHART_COLORS = ["#1D54E2", "#4F85EF", "#71A6F4", "#9CC5F9", "#C3DBFB"];

const ActiveLanguagesWidget: React.FC = () => {
  const { data, loading } = useActiveLanguagesWidgetQuery();
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const languagesInUse: Record<string, number> = useMemo(
    () =>
      data?.AdminEmployees?.objects.reduce(
        (acc: Record<string, number>, curr: LanguageCountFragment) => {
          const language = curr.language;
          if (acc[language]) {
            acc[language]++;
          } else {
            acc[language] = 1;
          }
          return acc;
        },
        {},
      ) ?? {},
    [data],
  );
  const chartData = useMemo(() => {
    return Object.entries(languagesInUse)
      .map(([language, count], i) => ({
        language,
        count,
        fill: CHART_COLORS[i % CHART_COLORS.length],
      }))
      .sort((a, b) => b.count - a.count);
  }, [languagesInUse]);
  const chartConfig = useMemo(() => {
    return chartData.reduce(
      (acc, { language, fill }) => {
        acc[language] = {
          label: langCodeToEnglishName(language as Language) || "",
          color: fill,
        };
        return acc;
      },
      {} as Record<string, { label: string; color: string }>,
    );
  }, [chartData]);
  const percentUsingOtherLanguages = useMemo(() => {
    const totalNonEnglishUsers =
      data?.AdminEmployees?.objects.reduce((acc, employee) => {
        return employee.language !== "en" ? acc + 1 : acc;
      }, 0) ?? 0;
    return Math.round(
      (totalNonEnglishUsers / (data?.AdminEmployees?.objects.length || 1)) *
        100,
    );
  }, [data]);

  const onPieEnter = useCallback(
    (_: Event, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  const router = useRouter();

  if (!loading && chartData.length < 2) {
    return null;
  }

  return (
    <Widget title="Active Languages">
      {loading ? (
        <Skeleton className="h-[278px] w-full rounded-xl" />
      ) : (
        <div className="flex h-full max-h-[300px] flex-col justify-center overflow-y-auto">
          <div className="flex flex-row items-center gap-2">
            <Text type="H1" className="flex" fontWeight="SemiBold">
              {percentUsingOtherLanguages}%
            </Text>
            <span className="flex max-w-[240px] whitespace-normal text-[12px] font-normal leading-[12px]">
              of your workforce is training in a language other than English
            </span>
          </div>
          <div className="mt-4 flex w-full flex-row flex-wrap items-center gap-2">
            <ChartContainer
              config={chartConfig}
              className="size-[172px] shrink-0"
            >
              <PieChart className="flex flex-col">
                <ChartTooltip
                  isAnimationActive={false}
                  content={<ChartTooltipContent nameKey="language" hideLabel />}
                />
                <Pie
                  activeIndex={activeIndex}
                  data={chartData}
                  dataKey="count"
                  isAnimationActive={false}
                  outerRadius="100%"
                  onMouseEnter={onPieEnter}
                  className="cursor-pointer"
                  onClick={(_, index) =>
                    router.push({
                      pathname: "/users",
                      query: { languages: [chartData[index].language] },
                    })
                  }
                />
              </PieChart>
            </ChartContainer>
            <div className="flex max-w-[215px] flex-row flex-wrap justify-center gap-2">
              {chartData.map((item) => (
                <div key={item.language} className="flex items-center">
                  <div
                    className="mr-2 size-2 rounded-sm"
                    style={{ backgroundColor: item.fill }}
                  />
                  <Text type="P3" className="capitalize">
                    {langCodeToEnglishName(item.language as Language) || ""}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Widget>
  );
};

gql`
  fragment LanguageCount on Employee {
    id
    language
  }
  query ActiveLanguagesWidget {
    AdminEmployees(userTypes: [MANAGER, EMPLOYEE]) {
      objects {
        ...LanguageCount
      }
    }
  }
`;

export default ActiveLanguagesWidget;

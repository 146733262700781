import { DateTime } from "luxon";

export const listToFirstItemPlus = (li: string[]): string => {
  const firstItem = li[0];
  const numAdditonalItems = li.length - 1;
  return li.length === 1 ? firstItem : firstItem + ` +${numAdditonalItems}`;
};

export function getRelativeDaysString(dt: string, locale = "en"): string {
  const datetime = DateTime.fromISO(dt).setLocale(locale);
  return (
    datetime.toRelativeCalendar({
      unit: "days",
    }) || ""
  );
}

export function getRelativeDaysNumber(dt: string, locale = "en"): number {
  const datetime = DateTime.fromISO(dt).setLocale(locale);
  const now = DateTime.now().setLocale(locale);
  const diff = now.diff(datetime, "days");
  return Math.floor(Math.abs(diff.days));
}

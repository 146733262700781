import Widget from "./Widget";
import { gql } from "@apollo/client";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";
import { useLocationAssignmentLeaderboardWidgetQuery } from "./LocationAssignmentLeaderboardWidget.generated";
import { LeaderboardWindow } from "@src/types.generated";
import { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@src/ui/table";
import Text from "@src/ui/text";
import { Skeleton } from "@src/ui/skeleton";
import EmptyWidgetContent from "./EmptyWidgetContent";

const getMedalEmoji = (index: number): string => {
  switch (index) {
    case 0:
      return "🥇";
    case 1:
      return "🥈";
    case 2:
      return "🥉";
    default:
      return `${index + 1}`;
  }
};

const LocationAssignmentLeaderboardWidget: React.FC = () => {
  const { data, loading } = useLocationAssignmentLeaderboardWidgetQuery({
    variables: {
      input: {
        window: LeaderboardWindow.AllTime,
        sort: { column: "assignmentsCompleted", descending: true },
      },
    },
  });
  const items = useMemo(() => data?.LocationLeaderboard.objects || [], [data]);
  if (!loading && items.length === 0) {
    return (
      <Widget title="Location Assignment Leaderboard" Icon={PinsOutlineIcon}>
        <EmptyWidgetContent
          illustration={
            <PinsOutlineIcon className="size-8 text-muted-foreground" />
          }
          description="You'll keep track of your top performing locations here"
        />
      </Widget>
    );
  }
  return (
    <Widget
      title="Location Assignment Leaderboard"
      Icon={PinsOutlineIcon}
      href={{ pathname: "/reporting/location-leaderboard" }}
    >
      <div className="mt-4 max-h-[300px] w-full overflow-y-auto overflow-x-hidden">
        {loading && <LocationAssignmentLeaderboardWidgetTableSkeleton />}
        {!loading && items.length > 0 && (
          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="text-gray-7">Location</TableHead>
                <TableHead className="text-right text-gray-7">
                  Completed
                </TableHead>
                <TableHead className="text-right text-gray-7">
                  Avg accuracy
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell className="flex font-medium">
                    <div className="mr-2 w-[20px]">
                      <Text type="P1" fontWeight="Regular">
                        {getMedalEmoji(index)}
                      </Text>
                    </div>
                    <Text type="P2" fontWeight="Regular">
                      {item.locationName}
                    </Text>
                  </TableCell>
                  <TableCell className="text-right">
                    <Text type="P2" fontWeight="Regular">
                      {item.assignmentsCompleted}
                    </Text>
                  </TableCell>
                  <TableCell className="text-right">
                    <Text type="P2" fontWeight="Regular">
                      {item.averageAccuracy?.toFixed(0)}%
                    </Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </Widget>
  );
};

const LocationAssignmentLeaderboardWidgetTableSkeleton: React.FC = () => (
  <Table className="w-full">
    <TableHeader>
      <TableRow>
        <TableHead className="text-gray-7">Location</TableHead>
        <TableHead className="text-right text-gray-7">Completed</TableHead>
        <TableHead className="text-right text-gray-7">Avg accuracy</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {[1, 2, 3, 4, 5].map((index) => (
        <TableRow key={index}>
          <TableCell className="flex font-medium">
            <div className="mr-2 w-[20px]">
              <Skeleton className="size-[20px] rounded-md" />
            </div>
            <Skeleton className="h-[20px] w-[150px] rounded-md" />
          </TableCell>
          <TableCell className="text-right">
            <Skeleton className="ml-auto h-[20px] w-[40px] rounded-md" />
          </TableCell>
          <TableCell className="text-right">
            <Skeleton className="ml-auto h-[20px] w-[40px] rounded-md" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

gql`
  fragment LocationAssignmentLeaderboardWidgetItem on LocationLeaderboardItem {
    id
    locationId
    locationName
    averageAccuracy
    assignmentsCompleted
    rank
  }
  query LocationAssignmentLeaderboardWidget($input: LocationLeaderboardInput!) {
    LocationLeaderboard(input: $input, pagination: { limit: 5 }) {
      totalCount
      objects {
        ...LocationAssignmentLeaderboardWidgetItem
      }
    }
  }
`;

export default LocationAssignmentLeaderboardWidget;

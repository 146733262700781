import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "@src/ui/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-[12px] font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-background shadow hover:bg-primary-hover",
        secondary:
          "border-transparent bg-secondary text-foreground hover:bg-secondary-hover",
        destructive:
          "border-transparent bg-destructive text-background shadow hover:bg-destructive-hover",
        outline: "text-foreground",
        success:
          "border-transparent text-green-11 bg-green-2 shadow hover:bg-green-3 border-none",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };

import Text from "@ui/text";
import { FC } from "react";
import { usePollInterval } from "@src/hooks/usePollInterval";
import { gql } from "@apollo/client";
import { ListPageContent } from "@src/components/layout/ListPage";
import { useMyTrainingPageMyTodoAssignmentsQuery } from "@src/components/my-training/MyTrainingPageContent.generated";
import useLanguage from "@src/hooks/useLanguage";
import { useRouter } from "next/router";
import { useTranslation } from "@src/utils/translationSets";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { LibraryItemType } from "@src/types.generated";
import { getDueDate, getStartDate } from "@src/components/my-training/utils";

export const NO_INCOMPLETE_ASSIGNMENTS_ENGLISH = "No incomplete assignments";

const MyTrainingPageContent: FC = () => {
  const pollInterval = usePollInterval(1000 * 60);
  const { data, loading } = useMyTrainingPageMyTodoAssignmentsQuery({
    pollInterval,
  });
  const language = useLanguage();
  const router = useRouter();
  const emptyTranslation = useTranslation(
    NO_INCOMPLETE_ASSIGNMENTS_ENGLISH,
    language,
  );
  const assignments = data?.MyTodoAssignments.objects || [];

  return (
    <ListPageContent>
      <Table
        loading={loading}
        noDataEmptyState={
          <div className="flex flex-1 self-stretch">
            <EmptyState
              title={emptyTranslation.text || NO_INCOMPLETE_ASSIGNMENTS_ENGLISH}
              icon="checkmark"
            />
          </div>
        }
        showCount={false}
        allItems={assignments}
        allColumns={["name", "type", "startedAt", "dueDate"]}
        disabledSorts={["name", "type", "startedAt", "dueDate"]}
        columnTitles={{
          name: "Name",
          type: "Type",
          startedAt: "Started At",
          dueDate: "Due Date",
        }}
        responsiveness={{
          name: {
            flex: 3,
          },
        }}
        onRowClick={(item) => {
          if (item.currentCourseInstance) {
            router.push({
              pathname: "/my-training/course-instance/[id]",
              query: { id: String(item.currentCourseInstance.id) },
            });
          } else if (item.currentPathInstance) {
            router.push({
              pathname: "/my-training/path-instance/[id]",
              query: { id: String(item.currentPathInstance.id) },
            });
          }
        }}
        renderFns={{
          name: (item) => (
            <AutoLayout alignmentVertical="center" spaceBetweenItems={8}>
              <ContentAvatar
                size={32}
                contentType="Module"
                style={{ borderRadius: 8 }}
                coverImage={
                  item.libraryItem.coverImage || item.libraryItem.coverImage
                }
                defaultBackgroundHashKey={item.libraryItem.id}
              />
              <Text type="P2">{item.libraryItem.name.myTranslation}</Text>
            </AutoLayout>
          ),

          type: (item) =>
            item.libraryItem.type === LibraryItemType.Course
              ? "Course"
              : "Module",
          startedAt: (item) =>
            getStartDate(
              item.currentCourseInstance?.startedAt ||
                item.currentPathInstance?.startedAt,
            ),

          dueDate: (item) =>
            getDueDate(
              item.currentCourseInstance?.dueDate ||
                item.currentPathInstance?.dueDate,
            ),
        }}
        itemId={(item) => item.id}
      />
    </ListPageContent>
  );
};

gql`
  query MyTrainingPageMyTodoAssignmentsCount {
    MyTodoAssignments {
      totalCount
    }
  }
  query MyTrainingPageMyTodoAssignments {
    MyTodoAssignments {
      objects {
        ...MyTrainingPageToDoAssignment
      }
    }
  }
  fragment MyTrainingPageToDoAssignment on Assignment {
    id
    status
    libraryItem {
      ...MyTrainingPageToDoLibraryItem
    }
    currentCourseInstance {
      ...MyTrainingPageToDoCourseInstance
    }
    currentPathInstance {
      ...MyTrainingPageToDoPathInstance
    }
  }
  fragment MyTrainingPageToDoLibraryItem on LibraryItem {
    id
    type
    name {
      ...MyTranslationSet
    }
    coverImage {
      ...CoverImage
    }
    description {
      ...MyTranslationSet
    }
  }
  fragment MyTrainingPageToDoPathInstance on PathInstance {
    id
    startedAt
    completedAt
    percentComplete
    dueDate
  }
  fragment MyTrainingPageToDoCourseInstance on CourseInstance {
    id
    track
    startedAt
    completedAt
    percentComplete
    dueDate
    certificateUrl
  }
`;

export default MyTrainingPageContent;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminEmployeesCountQueryVariables = Types.Exact<{
  pastWeekStartDate: Types.Scalars['Date'];
}>;


export type AdminEmployeesCountQuery = { __typename?: 'Query', TotalEmployees: { __typename?: 'Employees', totalCount: number }, PastWeekEmployees: { __typename?: 'Employees', totalCount: number } };


export const AdminEmployeesCountDocument = gql`
    query AdminEmployeesCount($pastWeekStartDate: Date!) {
  TotalEmployees: AdminEmployees {
    totalCount
  }
  PastWeekEmployees: AdminEmployees(startDate: $pastWeekStartDate) {
    totalCount
  }
}
    `;

/**
 * __useAdminEmployeesCountQuery__
 *
 * To run a query within a React component, call `useAdminEmployeesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminEmployeesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminEmployeesCountQuery({
 *   variables: {
 *      pastWeekStartDate: // value for 'pastWeekStartDate'
 *   },
 * });
 */
export function useAdminEmployeesCountQuery(baseOptions: Apollo.QueryHookOptions<AdminEmployeesCountQuery, AdminEmployeesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminEmployeesCountQuery, AdminEmployeesCountQueryVariables>(AdminEmployeesCountDocument, options);
      }
export function useAdminEmployeesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminEmployeesCountQuery, AdminEmployeesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminEmployeesCountQuery, AdminEmployeesCountQueryVariables>(AdminEmployeesCountDocument, options);
        }
export type AdminEmployeesCountQueryHookResult = ReturnType<typeof useAdminEmployeesCountQuery>;
export type AdminEmployeesCountLazyQueryHookResult = ReturnType<typeof useAdminEmployeesCountLazyQuery>;
export type AdminEmployeesCountQueryResult = Apollo.QueryResult<AdminEmployeesCountQuery, AdminEmployeesCountQueryVariables>;
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncompleteAssignmentsWidgetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IncompleteAssignmentsWidgetQuery = { __typename?: 'Query', AdminEmployeesWithIncompleteAssignments: { __typename?: 'Employees', totalCount: number }, AdminEmployeesWithPastDueAssignments: { __typename?: 'Employees', totalCount: number } };


export const IncompleteAssignmentsWidgetDocument = gql`
    query IncompleteAssignmentsWidget {
  AdminEmployeesWithIncompleteAssignments(input: {}) {
    totalCount
  }
  AdminEmployeesWithPastDueAssignments(input: {}) {
    totalCount
  }
}
    `;

/**
 * __useIncompleteAssignmentsWidgetQuery__
 *
 * To run a query within a React component, call `useIncompleteAssignmentsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncompleteAssignmentsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncompleteAssignmentsWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useIncompleteAssignmentsWidgetQuery(baseOptions?: Apollo.QueryHookOptions<IncompleteAssignmentsWidgetQuery, IncompleteAssignmentsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncompleteAssignmentsWidgetQuery, IncompleteAssignmentsWidgetQueryVariables>(IncompleteAssignmentsWidgetDocument, options);
      }
export function useIncompleteAssignmentsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncompleteAssignmentsWidgetQuery, IncompleteAssignmentsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncompleteAssignmentsWidgetQuery, IncompleteAssignmentsWidgetQueryVariables>(IncompleteAssignmentsWidgetDocument, options);
        }
export type IncompleteAssignmentsWidgetQueryHookResult = ReturnType<typeof useIncompleteAssignmentsWidgetQuery>;
export type IncompleteAssignmentsWidgetLazyQueryHookResult = ReturnType<typeof useIncompleteAssignmentsWidgetLazyQuery>;
export type IncompleteAssignmentsWidgetQueryResult = Apollo.QueryResult<IncompleteAssignmentsWidgetQuery, IncompleteAssignmentsWidgetQueryVariables>;
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersNoRoleWidgetCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersNoRoleWidgetCountQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number } };


export const UsersNoRoleWidgetCountDocument = gql`
    query UsersNoRoleWidgetCount {
  People(
    input: {hasRole: {value: false}, userTypes: {value: [EMPLOYEE, MANAGER]}}
  ) {
    totalCount
  }
}
    `;

/**
 * __useUsersNoRoleWidgetCountQuery__
 *
 * To run a query within a React component, call `useUsersNoRoleWidgetCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersNoRoleWidgetCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersNoRoleWidgetCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersNoRoleWidgetCountQuery(baseOptions?: Apollo.QueryHookOptions<UsersNoRoleWidgetCountQuery, UsersNoRoleWidgetCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersNoRoleWidgetCountQuery, UsersNoRoleWidgetCountQueryVariables>(UsersNoRoleWidgetCountDocument, options);
      }
export function useUsersNoRoleWidgetCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersNoRoleWidgetCountQuery, UsersNoRoleWidgetCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersNoRoleWidgetCountQuery, UsersNoRoleWidgetCountQueryVariables>(UsersNoRoleWidgetCountDocument, options);
        }
export type UsersNoRoleWidgetCountQueryHookResult = ReturnType<typeof useUsersNoRoleWidgetCountQuery>;
export type UsersNoRoleWidgetCountLazyQueryHookResult = ReturnType<typeof useUsersNoRoleWidgetCountLazyQuery>;
export type UsersNoRoleWidgetCountQueryResult = Apollo.QueryResult<UsersNoRoleWidgetCountQuery, UsersNoRoleWidgetCountQueryVariables>;
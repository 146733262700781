import Widget from "./Widget";
import { gql } from "@apollo/client";
import Text from "@src/ui/text";

import { Skeleton } from "@src/ui/skeleton";
import { useTotalLocationsCountQuery } from "./TotalLocationsWidget.generated";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";

const TotalLocationsWidget: React.FC = () => {
  const { data, loading } = useTotalLocationsCountQuery();

  return (
    <Widget
      title="Total Locations"
      Icon={PinsOutlineIcon}
      href={{ pathname: "/locations" }}
    >
      {loading ? (
        <Skeleton className="h-[34px] w-[40px] rounded-md" />
      ) : (
        <Text type="H1" fontWeight="SemiBold">
          {data?.Locations?.totalCount}
        </Text>
      )}
    </Widget>
  );
};

gql`
  query TotalLocationsCount {
    Locations {
      totalCount
    }
  }
`;

export default TotalLocationsWidget;

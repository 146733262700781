import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotInvitedUsersSheetHrisEmployeesQueryVariables = Types.Exact<{
  input: Types.HrisEmployeesInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type NotInvitedUsersSheetHrisEmployeesQuery = { __typename?: 'Query', HrisEmployees: { __typename?: 'HrisEmployees', totalCount: number, objects: Array<{ __typename?: 'HrisEmployee', id: number, firstName: string, lastName: string, phoneNumber?: string | null }> } };

export type NotInvitedUsersSheetHrisEmployeeFragment = { __typename?: 'HrisEmployee', id: number, firstName: string, lastName: string, phoneNumber?: string | null };

export const NotInvitedUsersSheetHrisEmployeeFragmentDoc = gql`
    fragment NotInvitedUsersSheetHrisEmployee on HrisEmployee {
  id
  firstName
  lastName
  phoneNumber
}
    `;
export const NotInvitedUsersSheetHrisEmployeesDocument = gql`
    query NotInvitedUsersSheetHrisEmployees($input: HrisEmployeesInput!, $pagination: PaginationInput) {
  HrisEmployees(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...NotInvitedUsersSheetHrisEmployee
    }
  }
}
    ${NotInvitedUsersSheetHrisEmployeeFragmentDoc}`;

/**
 * __useNotInvitedUsersSheetHrisEmployeesQuery__
 *
 * To run a query within a React component, call `useNotInvitedUsersSheetHrisEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotInvitedUsersSheetHrisEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotInvitedUsersSheetHrisEmployeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useNotInvitedUsersSheetHrisEmployeesQuery(baseOptions: Apollo.QueryHookOptions<NotInvitedUsersSheetHrisEmployeesQuery, NotInvitedUsersSheetHrisEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotInvitedUsersSheetHrisEmployeesQuery, NotInvitedUsersSheetHrisEmployeesQueryVariables>(NotInvitedUsersSheetHrisEmployeesDocument, options);
      }
export function useNotInvitedUsersSheetHrisEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotInvitedUsersSheetHrisEmployeesQuery, NotInvitedUsersSheetHrisEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotInvitedUsersSheetHrisEmployeesQuery, NotInvitedUsersSheetHrisEmployeesQueryVariables>(NotInvitedUsersSheetHrisEmployeesDocument, options);
        }
export type NotInvitedUsersSheetHrisEmployeesQueryHookResult = ReturnType<typeof useNotInvitedUsersSheetHrisEmployeesQuery>;
export type NotInvitedUsersSheetHrisEmployeesLazyQueryHookResult = ReturnType<typeof useNotInvitedUsersSheetHrisEmployeesLazyQuery>;
export type NotInvitedUsersSheetHrisEmployeesQueryResult = Apollo.QueryResult<NotInvitedUsersSheetHrisEmployeesQuery, NotInvitedUsersSheetHrisEmployeesQueryVariables>;
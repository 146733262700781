import { FC } from "react";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";

type Props = {
  rating: number;
  size?: number;
};

const RatingStars: FC<Props> = ({ rating, size = 24 }) => {
  return (
    <div style={{ display: "grid" }}>
      <div style={{ display: "flex", gridRowStart: 1, gridColumnStart: 1 }}>
        {[1, 2, 3, 4, 5].map((num) => {
          return (
            <DeprecatedIcon
              key={num}
              type="star"
              iconSize={size}
              containerSize={size}
              color={deprecatedTones.yellow2}
              svgStyle={{ fill: deprecatedTones.yellow2 }}
              style={{ marginLeft: num > 0 ? -4 : 0 }}
            />
          );
        })}
      </div>
      <div style={{ display: "flex", gridRowStart: 1, gridColumnStart: 1 }}>
        {[1, 2, 3, 4, 5].map((num) => {
          const hasFill = Math.ceil(rating) >= num;
          if (!hasFill) {
            return null;
          }
          const percentage = Math.min(rating + 1 - num, 1);
          return (
            <div
              key={num}
              style={{
                width: size * percentage,
                overflow: "hidden",
                marginLeft: num > 0 ? -4 : 0,
              }}
            >
              <DeprecatedIcon
                type="star"
                iconSize={size}
                containerSize={size}
                color={deprecatedTones.yellow9}
                svgStyle={{ fill: deprecatedTones.yellow9 }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatingStars;

import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PremiumPathDetailCertificatesTab_ComplianceRecordFragment = { __typename?: 'ComplianceRecord', id: string, complianceType: Types.ComplianceType, compliantUntil?: string | null, user: { __typename?: 'Employee', id: number, name: string, firstName: string, lastName: string, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }>, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, startDate?: string | null } | null }, attempts: Array<{ __typename?: 'ComplianceAttempt', id: string, createdAt: string, becameCompliantAt?: string | null, compliantUntil?: string | null, usState?: USState | null, assignedUserType?: Types.AssignedUserType | null, importedFromExternalSource: boolean, pathInstance?: { __typename?: 'PathInstance', id: string, status: AttemptStatus, certificateUrl?: string | null, assignment: { __typename?: 'Assignment', id: string } } | null, courseInstance?: { __typename?: 'CourseInstance', id: number, status: AttemptStatus, certificateUrl?: string | null, assignment: { __typename?: 'Assignment', id: string } } | null }> };

export type PremiumPathDetailCertifricatesTab_ComplianceAttemptFragment = { __typename?: 'ComplianceAttempt', id: string, createdAt: string, becameCompliantAt?: string | null, compliantUntil?: string | null, usState?: USState | null, assignedUserType?: Types.AssignedUserType | null, importedFromExternalSource: boolean, pathInstance?: { __typename?: 'PathInstance', id: string, status: AttemptStatus, certificateUrl?: string | null, assignment: { __typename?: 'Assignment', id: string } } | null, courseInstance?: { __typename?: 'CourseInstance', id: number, status: AttemptStatus, certificateUrl?: string | null, assignment: { __typename?: 'Assignment', id: string } } | null };

export type ComplianceRecordsQueryVariables = Types.Exact<{
  input: Types.ComplianceRecordsInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type ComplianceRecordsQuery = { __typename?: 'Query', ComplianceRecords: { __typename?: 'ComplianceRecords', totalCount: number, objects: Array<{ __typename?: 'ComplianceRecord', id: string, complianceType: Types.ComplianceType, compliantUntil?: string | null, user: { __typename?: 'Employee', id: number, name: string, firstName: string, lastName: string, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }>, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, startDate?: string | null } | null }, attempts: Array<{ __typename?: 'ComplianceAttempt', id: string, createdAt: string, becameCompliantAt?: string | null, compliantUntil?: string | null, usState?: USState | null, assignedUserType?: Types.AssignedUserType | null, importedFromExternalSource: boolean, pathInstance?: { __typename?: 'PathInstance', id: string, status: AttemptStatus, certificateUrl?: string | null, assignment: { __typename?: 'Assignment', id: string } } | null, courseInstance?: { __typename?: 'CourseInstance', id: number, status: AttemptStatus, certificateUrl?: string | null, assignment: { __typename?: 'Assignment', id: string } } | null }> }> } };

export type ComplianceSummaryFragment = { __typename?: 'ComplianceRecord', id: string, compliantUntil?: string | null };

export type ComplianceSummaryQueryVariables = Types.Exact<{
  input: Types.ComplianceRecordsInput;
}>;


export type ComplianceSummaryQuery = { __typename?: 'Query', ComplianceRecords: { __typename?: 'ComplianceRecords', objects: Array<{ __typename?: 'ComplianceRecord', id: string, compliantUntil?: string | null }> } };

export const PremiumPathDetailCertifricatesTab_ComplianceAttemptFragmentDoc = gql`
    fragment PremiumPathDetailCertifricatesTab_ComplianceAttempt on ComplianceAttempt {
  id
  createdAt
  becameCompliantAt
  compliantUntil
  usState
  assignedUserType
  importedFromExternalSource
  pathInstance {
    id
    status
    certificateUrl
    assignment {
      id
    }
  }
  courseInstance {
    id
    status
    certificateUrl
    assignment {
      id
    }
  }
}
    `;
export const PremiumPathDetailCertificatesTab_ComplianceRecordFragmentDoc = gql`
    fragment PremiumPathDetailCertificatesTab_ComplianceRecord on ComplianceRecord {
  id
  complianceType
  user {
    id
    name
    firstName
    lastName
    locations {
      id
      name
    }
    roles {
      id
      name
    }
    hrisEmployee {
      id
      remoteHrisId
      startDate
    }
  }
  compliantUntil
  attempts {
    ...PremiumPathDetailCertifricatesTab_ComplianceAttempt
  }
}
    ${PremiumPathDetailCertifricatesTab_ComplianceAttemptFragmentDoc}`;
export const ComplianceSummaryFragmentDoc = gql`
    fragment ComplianceSummary on ComplianceRecord {
  id
  compliantUntil
}
    `;
export const ComplianceRecordsDocument = gql`
    query ComplianceRecords($input: ComplianceRecordsInput!, $pagination: PaginationInput) {
  ComplianceRecords(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...PremiumPathDetailCertificatesTab_ComplianceRecord
    }
  }
}
    ${PremiumPathDetailCertificatesTab_ComplianceRecordFragmentDoc}`;

/**
 * __useComplianceRecordsQuery__
 *
 * To run a query within a React component, call `useComplianceRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useComplianceRecordsQuery(baseOptions: Apollo.QueryHookOptions<ComplianceRecordsQuery, ComplianceRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComplianceRecordsQuery, ComplianceRecordsQueryVariables>(ComplianceRecordsDocument, options);
      }
export function useComplianceRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComplianceRecordsQuery, ComplianceRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComplianceRecordsQuery, ComplianceRecordsQueryVariables>(ComplianceRecordsDocument, options);
        }
export type ComplianceRecordsQueryHookResult = ReturnType<typeof useComplianceRecordsQuery>;
export type ComplianceRecordsLazyQueryHookResult = ReturnType<typeof useComplianceRecordsLazyQuery>;
export type ComplianceRecordsQueryResult = Apollo.QueryResult<ComplianceRecordsQuery, ComplianceRecordsQueryVariables>;
export const ComplianceSummaryDocument = gql`
    query ComplianceSummary($input: ComplianceRecordsInput!) {
  ComplianceRecords(input: $input) {
    objects {
      ...ComplianceSummary
    }
  }
}
    ${ComplianceSummaryFragmentDoc}`;

/**
 * __useComplianceSummaryQuery__
 *
 * To run a query within a React component, call `useComplianceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComplianceSummaryQuery(baseOptions: Apollo.QueryHookOptions<ComplianceSummaryQuery, ComplianceSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComplianceSummaryQuery, ComplianceSummaryQueryVariables>(ComplianceSummaryDocument, options);
      }
export function useComplianceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComplianceSummaryQuery, ComplianceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComplianceSummaryQuery, ComplianceSummaryQueryVariables>(ComplianceSummaryDocument, options);
        }
export type ComplianceSummaryQueryHookResult = ReturnType<typeof useComplianceSummaryQuery>;
export type ComplianceSummaryLazyQueryHookResult = ReturnType<typeof useComplianceSummaryLazyQuery>;
export type ComplianceSummaryQueryResult = Apollo.QueryResult<ComplianceSummaryQuery, ComplianceSummaryQueryVariables>;
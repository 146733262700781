import Widget from "./Widget";
import { gql } from "@apollo/client";

import Text from "@src/ui/text";
import StudentIcon from "@src/ui/icons/18px/student";
import ContentAvatar, {
  LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { Progress } from "@src/ui/progress";
import { Badge } from "@src/ui/badge";
import Link from "next/link";
import {
  RecentlyAssignedLibraryItem__WidgetFragment,
  useRecentlyAssignedLibraryItemsQuery,
} from "./RecentAssignmentsWidget.generated";
import { useMemo } from "react";
import { DateTime } from "luxon";

const RecentAssignmentsWidget: React.FC = () => {
  const sinceDate = useMemo(
    () => DateTime.now().minus({ days: 7 }).toJSDate().toISOString(),
    [],
  );
  const { data } = useRecentlyAssignedLibraryItemsQuery({
    variables: {
      since: sinceDate,
    },
  });
  if (
    data?.RecentlyAssignedLibraryItems.length === 0 ||
    !data?.RecentlyAssignedLibraryItems
  ) {
    return null;
  }
  return (
    <Widget title="Recent Assignments" Icon={StudentIcon}>
      <div className="flex max-h-[300px] w-full flex-col gap-2 overflow-y-auto">
        <div className="sticky top-0 z-20 mb-2 flex items-center border-b border-border bg-background pb-2">
          <div className="flex flex-1">
            <Text
              type="P3"
              fontWeight="Medium"
              className="text-muted-foreground"
            >
              Assignment
            </Text>
          </div>
          <div className="flex flex-1 justify-end">
            <Text
              type="P3"
              fontWeight="Medium"
              className="text-muted-foreground"
            >
              % Completed
            </Text>
          </div>
          <div className="flex flex-1 justify-end">
            <Text
              type="P3"
              fontWeight="Medium"
              className="text-muted-foreground"
            >
              Assigned last 7 days
            </Text>
          </div>
        </div>
        {data?.RecentlyAssignedLibraryItems?.map?.((item) => (
          <AssignmentItem key={item.id} item={item} />
        ))}
      </div>
    </Widget>
  );
};

const AssignmentItem: React.FC<{
  item: RecentlyAssignedLibraryItem__WidgetFragment;
}> = ({ item }) => {
  const {
    numAssignedTotal,
    numCompletedTotal,
    id,
    libraryItem: {
      type,
      name: { en: name },
      coverImage,
    },
    numAssignedSince,
  } = item;
  const progress =
    numAssignedTotal > 0
      ? Math.round((numCompletedTotal / numAssignedTotal) * 100)
      : 0;
  return (
    <Link
      href={{
        pathname: "/library/library-item/[id]",
        query: {
          id,
          mainTab: "manage",
          manageTab: "assignments",
        },
      }}
    >
      <div className="flex flex-row items-center gap-2">
        <ContentAvatar
          defaultBackgroundHashKey={id}
          contentType={LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE[type]}
          coverImage={coverImage}
        />
        <div className="flex flex-1 grow flex-col hover:underline">
          <Text type="P2" ellipsis>
            {name}
          </Text>
          <Text type="P3">{LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE[type]}</Text>
        </div>
        <div className="flex flex-1 flex-row items-center gap-2">
          <Progress value={progress} className="bg-blue-1" />
          <Text type="P2">{progress}%</Text>
        </div>
        <div className="flex min-w-[60px] justify-end">
          <Badge variant="success">
            <Text type="P2">+{numAssignedSince}</Text>
          </Badge>
        </div>
        <Text type="P3" className="text-gray-8">
          assigned last 7 days
        </Text>
      </div>
    </Link>
  );
};

gql`
  fragment RecentlyAssignedLibraryItem__Widget on RecentlyAssignedLibraryItem {
    id
    libraryItem {
      id
      coverImage {
        ...CoverImage
      }
      name {
        en
      }
      type
    }
    numAssignedSince
    numAssignedTotal
    numCompletedTotal
  }
  query RecentlyAssignedLibraryItems($since: DateTime!) {
    RecentlyAssignedLibraryItems(input: { since: { value: $since } }) {
      ...RecentlyAssignedLibraryItem__Widget
    }
  }
`;
export default RecentAssignmentsWidget;

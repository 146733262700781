import { DateTime } from "luxon";
import { gql } from "@apollo/client";
import { useInactiveUsersCountQuery } from "@src/components/home/hooks/useGetInactiveUsersCounts.generated";

export const NUM_DAYS_WITHOUT_ACTVITIY = 90;
export const NUM_DAYS_SINCE_LAST_CONFIRMED_EMPLOYMENT = 30;

export const useGetInactiveUserCounts = (): ReturnType<
  typeof useInactiveUsersCountQuery
> => {
  return useInactiveUsersCountQuery({
    variables: {
      input: {
        inactiveSince: {
          value: DateTime.now()
            .minus({ days: NUM_DAYS_WITHOUT_ACTVITIY })
            .toISODate(),
        },
        excludeConfirmedStillEmployedAfter: {
          value: DateTime.now()
            .minus({ days: NUM_DAYS_SINCE_LAST_CONFIRMED_EMPLOYMENT })
            .toISODate(),
        },
      },
    },
  });
};

gql`
  query InactiveUsersCount($input: GetPeopleInput!) {
    People(input: $input) {
      totalCount
    }
  }
`;

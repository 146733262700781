import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotInvitedUsersWidgetHrisEmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NotInvitedUsersWidgetHrisEmployeesQuery = { __typename?: 'Query', HrisEmployees: { __typename?: 'HrisEmployees', totalCount: number } };

export type NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NotInvitedUsersWidgetNotInvitedHrisEmployeesQuery = { __typename?: 'Query', NotInvitedHrisEmployees: { __typename?: 'HrisEmployees', totalCount: number } };


export const NotInvitedUsersWidgetHrisEmployeesDocument = gql`
    query NotInvitedUsersWidgetHrisEmployees {
  HrisEmployees(input: {}) {
    totalCount
  }
}
    `;

/**
 * __useNotInvitedUsersWidgetHrisEmployeesQuery__
 *
 * To run a query within a React component, call `useNotInvitedUsersWidgetHrisEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotInvitedUsersWidgetHrisEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotInvitedUsersWidgetHrisEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotInvitedUsersWidgetHrisEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<NotInvitedUsersWidgetHrisEmployeesQuery, NotInvitedUsersWidgetHrisEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotInvitedUsersWidgetHrisEmployeesQuery, NotInvitedUsersWidgetHrisEmployeesQueryVariables>(NotInvitedUsersWidgetHrisEmployeesDocument, options);
      }
export function useNotInvitedUsersWidgetHrisEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotInvitedUsersWidgetHrisEmployeesQuery, NotInvitedUsersWidgetHrisEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotInvitedUsersWidgetHrisEmployeesQuery, NotInvitedUsersWidgetHrisEmployeesQueryVariables>(NotInvitedUsersWidgetHrisEmployeesDocument, options);
        }
export type NotInvitedUsersWidgetHrisEmployeesQueryHookResult = ReturnType<typeof useNotInvitedUsersWidgetHrisEmployeesQuery>;
export type NotInvitedUsersWidgetHrisEmployeesLazyQueryHookResult = ReturnType<typeof useNotInvitedUsersWidgetHrisEmployeesLazyQuery>;
export type NotInvitedUsersWidgetHrisEmployeesQueryResult = Apollo.QueryResult<NotInvitedUsersWidgetHrisEmployeesQuery, NotInvitedUsersWidgetHrisEmployeesQueryVariables>;
export const NotInvitedUsersWidgetNotInvitedHrisEmployeesDocument = gql`
    query NotInvitedUsersWidgetNotInvitedHrisEmployees {
  NotInvitedHrisEmployees: HrisEmployees(
    input: {filters: {inviteStatus: {value: false}}}
  ) {
    totalCount
  }
}
    `;

/**
 * __useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery__
 *
 * To run a query within a React component, call `useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<NotInvitedUsersWidgetNotInvitedHrisEmployeesQuery, NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotInvitedUsersWidgetNotInvitedHrisEmployeesQuery, NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryVariables>(NotInvitedUsersWidgetNotInvitedHrisEmployeesDocument, options);
      }
export function useNotInvitedUsersWidgetNotInvitedHrisEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotInvitedUsersWidgetNotInvitedHrisEmployeesQuery, NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotInvitedUsersWidgetNotInvitedHrisEmployeesQuery, NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryVariables>(NotInvitedUsersWidgetNotInvitedHrisEmployeesDocument, options);
        }
export type NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryHookResult = ReturnType<typeof useNotInvitedUsersWidgetNotInvitedHrisEmployeesQuery>;
export type NotInvitedUsersWidgetNotInvitedHrisEmployeesLazyQueryHookResult = ReturnType<typeof useNotInvitedUsersWidgetNotInvitedHrisEmployeesLazyQuery>;
export type NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryResult = Apollo.QueryResult<NotInvitedUsersWidgetNotInvitedHrisEmployeesQuery, NotInvitedUsersWidgetNotInvitedHrisEmployeesQueryVariables>;
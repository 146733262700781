import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationAssignmentLeaderboardWidgetItemFragment = { __typename?: 'LocationLeaderboardItem', id: string, locationId: number, locationName: string, averageAccuracy?: number | null, assignmentsCompleted: number, rank: number };

export type LocationAssignmentLeaderboardWidgetQueryVariables = Types.Exact<{
  input: Types.LocationLeaderboardInput;
}>;


export type LocationAssignmentLeaderboardWidgetQuery = { __typename?: 'Query', LocationLeaderboard: { __typename?: 'LocationLeaderboard', totalCount: number, objects: Array<{ __typename?: 'LocationLeaderboardItem', id: string, locationId: number, locationName: string, averageAccuracy?: number | null, assignmentsCompleted: number, rank: number }> } };

export const LocationAssignmentLeaderboardWidgetItemFragmentDoc = gql`
    fragment LocationAssignmentLeaderboardWidgetItem on LocationLeaderboardItem {
  id
  locationId
  locationName
  averageAccuracy
  assignmentsCompleted
  rank
}
    `;
export const LocationAssignmentLeaderboardWidgetDocument = gql`
    query LocationAssignmentLeaderboardWidget($input: LocationLeaderboardInput!) {
  LocationLeaderboard(input: $input, pagination: {limit: 5}) {
    totalCount
    objects {
      ...LocationAssignmentLeaderboardWidgetItem
    }
  }
}
    ${LocationAssignmentLeaderboardWidgetItemFragmentDoc}`;

/**
 * __useLocationAssignmentLeaderboardWidgetQuery__
 *
 * To run a query within a React component, call `useLocationAssignmentLeaderboardWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationAssignmentLeaderboardWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationAssignmentLeaderboardWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationAssignmentLeaderboardWidgetQuery(baseOptions: Apollo.QueryHookOptions<LocationAssignmentLeaderboardWidgetQuery, LocationAssignmentLeaderboardWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationAssignmentLeaderboardWidgetQuery, LocationAssignmentLeaderboardWidgetQueryVariables>(LocationAssignmentLeaderboardWidgetDocument, options);
      }
export function useLocationAssignmentLeaderboardWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationAssignmentLeaderboardWidgetQuery, LocationAssignmentLeaderboardWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationAssignmentLeaderboardWidgetQuery, LocationAssignmentLeaderboardWidgetQueryVariables>(LocationAssignmentLeaderboardWidgetDocument, options);
        }
export type LocationAssignmentLeaderboardWidgetQueryHookResult = ReturnType<typeof useLocationAssignmentLeaderboardWidgetQuery>;
export type LocationAssignmentLeaderboardWidgetLazyQueryHookResult = ReturnType<typeof useLocationAssignmentLeaderboardWidgetLazyQuery>;
export type LocationAssignmentLeaderboardWidgetQueryResult = Apollo.QueryResult<LocationAssignmentLeaderboardWidgetQuery, LocationAssignmentLeaderboardWidgetQueryVariables>;
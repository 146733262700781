import Text from "@src/ui/text";
import Widget from "@src/components/home/Widget";
import { Skeleton } from "@src/ui/skeleton";
import { useGetInactiveUserCounts } from "@src/components/home/hooks/useGetInactiveUsersCounts";
import InactiveUsersSheet from "@src/components/home/InactiveUsersSheet";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";

const InactiveUsersWidget: React.FC = () => {
  const { data, loading } = useGetInactiveUserCounts();
  const inactiveUserCount = data?.People.totalCount || 0;
  return (
    <Widget
      title="Inactive"
      className="flex-1 self-stretch shadow-none"
      Icon={UsersOutlineIcon}
    >
      {loading && !data ? (
        <Skeleton className="h-[34px] w-10 rounded-md" />
      ) : (
        <div className="flex flex-row items-center gap-1 align-middle">
          <InactiveUsersSheet>
            <Text
              type="H1"
              fontWeight="SemiBold"
              className="cursor-pointer hover:underline"
            >
              {inactiveUserCount}
            </Text>
          </InactiveUsersSheet>
        </div>
      )}
    </Widget>
  );
};

export default InactiveUsersWidget;

import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LanguageCountFragment = { __typename?: 'Employee', id: number, language: Language };

export type ActiveLanguagesWidgetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveLanguagesWidgetQuery = { __typename?: 'Query', AdminEmployees: { __typename?: 'Employees', objects: Array<{ __typename?: 'Employee', id: number, language: Language }> } };

export const LanguageCountFragmentDoc = gql`
    fragment LanguageCount on Employee {
  id
  language
}
    `;
export const ActiveLanguagesWidgetDocument = gql`
    query ActiveLanguagesWidget {
  AdminEmployees(userTypes: [MANAGER, EMPLOYEE]) {
    objects {
      ...LanguageCount
    }
  }
}
    ${LanguageCountFragmentDoc}`;

/**
 * __useActiveLanguagesWidgetQuery__
 *
 * To run a query within a React component, call `useActiveLanguagesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLanguagesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLanguagesWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLanguagesWidgetQuery(baseOptions?: Apollo.QueryHookOptions<ActiveLanguagesWidgetQuery, ActiveLanguagesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveLanguagesWidgetQuery, ActiveLanguagesWidgetQueryVariables>(ActiveLanguagesWidgetDocument, options);
      }
export function useActiveLanguagesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveLanguagesWidgetQuery, ActiveLanguagesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveLanguagesWidgetQuery, ActiveLanguagesWidgetQueryVariables>(ActiveLanguagesWidgetDocument, options);
        }
export type ActiveLanguagesWidgetQueryHookResult = ReturnType<typeof useActiveLanguagesWidgetQuery>;
export type ActiveLanguagesWidgetLazyQueryHookResult = ReturnType<typeof useActiveLanguagesWidgetLazyQuery>;
export type ActiveLanguagesWidgetQueryResult = Apollo.QueryResult<ActiveLanguagesWidgetQuery, ActiveLanguagesWidgetQueryVariables>;
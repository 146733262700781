import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

type Props = {
  queryParamName: string;
  initialValue: string;
  delay?: number;
};
type Return = {
  value: string;
  setValue: (newValue: string) => void;
  debouncedValue: string;
};

export const useDebouncedQueryParam = (props: Props): Return => {
  const [value, setValue] = useQueryParam(
    props.queryParamName,
    withDefault(StringParam, props.initialValue),
    {
      updateType: "replaceIn",
    },
  );
  const [debouncedValue, setDebouncedValue] = useState(props.initialValue);
  const debouncer = useMemo(() => {
    return debounce(setDebouncedValue, props.delay);
  }, [props.delay]);

  useEffect(() => {
    debouncer(value);
  }, [value, debouncer]);

  return useMemo(
    () => ({
      value,
      setValue,
      debouncedValue,
    }),
    [value, setValue, debouncedValue],
  );
};

import Widget from "./Widget";
import { useMemo } from "react";
import ChatBubbleOutlineIcon from "@src/ui/icons/18px/chat-bubble-outline";
import Text from "@src/ui/text";
import { Skeleton } from "@src/ui/skeleton";
import RatingStars from "../contentDetail/courseDetail/assignments/RatingStars";
import Link from "@src/deprecatedDesignSystem/components/Link";
import { formatDateTime } from "@src/utils/dates";
import { parseISO } from "date-fns";
import EmptyWidgetContent from "./EmptyWidgetContent";
import { gql } from "@apollo/client";
import { useRecentCourseFeedbackQuery } from "@src/components/home/RecentFeedbackWidget.generated";

const RecentFeedbackWidget: React.FC = () => {
  const { data, loading } = useRecentCourseFeedbackQuery();
  const feedbackItems = useMemo(() => data?.courseFeedback || [], [data]);

  if (!loading && feedbackItems.length === 0) {
    return (
      <Widget title="Recent Feedback" Icon={ChatBubbleOutlineIcon}>
        <EmptyWidgetContent
          illustration={
            <ChatBubbleOutlineIcon className="size-8 text-muted-foreground" />
          }
          description="Feedback on your training content from your team will show up here"
        />
      </Widget>
    );
  }
  return (
    <Widget title="Recent Feedback" Icon={ChatBubbleOutlineIcon}>
      <div className="flex max-h-[300px] flex-col gap-1 overflow-y-auto">
        {!loading && (
          <>
            {feedbackItems.map((item) => (
              <div key={item.id} className="border-b py-1">
                <div className="mb-2 flex items-start justify-between">
                  <div className="flex flex-col ">
                    <Link
                      href={{
                        pathname: "/library/library-item/[id]",
                        query: {
                          id: item.courseInstance.course.libraryItem.id,
                        },
                      }}
                    >
                      <Text
                        type="P2"
                        className="pb-1 text-foreground hover:underline"
                      >
                        {item.courseInstance.course.libraryItem.name.en}
                      </Text>
                    </Link>
                    <Link
                      href={{
                        pathname: "/users/[id]",
                        query: { id: item.courseInstance.user.id.toString() },
                      }}
                    >
                      <Text
                        type="P3"
                        className="pb-1 text-muted-foreground hover:underline"
                      >
                        {item.courseInstance.user.name} -{" "}
                        {formatDateTime(parseISO(item.createdAt))}
                      </Text>
                    </Link>
                  </div>

                  <RatingStars rating={item.rating} size={20} />
                </div>
                {item.comment && (
                  <Text type="P2" className="pb-1 text-foreground">
                    "{item.comment}"
                  </Text>
                )}
              </div>
            ))}
          </>
        )}
        {loading && <Skeleton className="min-h-[200px] w-full rounded-xl" />}
      </div>
    </Widget>
  );
};

gql`
  query RecentCourseFeedback {
    courseFeedback: AdminCourseFeedback(pagination: { limit: 20 }) {
      ...CourseFeedbackFull
    }
  }

  fragment CourseFeedbackFull on CourseFeedback {
    id
    createdAt
    comment
    rating
    courseInstance {
      ...RecentFeedbackCourseInstance
    }
  }

  fragment RecentFeedbackCourseInstance on CourseInstance {
    id
    completedAt
    user {
      id
      name
    }
    course {
      id
      libraryItem {
        id
        name {
          ...TranslationSet
        }
      }
    }
  }
`;

export default RecentFeedbackWidget;

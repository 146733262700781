import React from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@src/ui/sheet";
import { gql } from "@apollo/client";
import PaginatedTable from "@src/deprecatedDesignSystem/components/table/PaginatedTable";
import UserNameLocationsRolesTableCell from "@src/components/home/UserNameLocationsRolesTableCell";
import {
  IncompleteAssignmentsSheetEmployeeFragment,
  useIncompleteAssignmentsSheetLazyQuery,
  useIncompleteAssignmentsSheetQuery,
} from "@src/components/home/IncompleteAssignmentsSheet.generated";

type Props = {
  children: React.ReactNode;
};

type Column = "name" | "incomplete";
const exportColumns = ["name", "incomplete"] as const;
type ExportColumn = (typeof exportColumns)[number];

const IncompleteAssignmentsSheet: React.FC<Props> = (props) => {
  return (
    <Sheet>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Incomplete Assignments</SheetTitle>
          <SheetDescription>Users with incomplete assignments</SheetDescription>
        </SheetHeader>
        <PaginatedTable<
          IncompleteAssignmentsSheetEmployeeFragment,
          Column,
          typeof useIncompleteAssignmentsSheetQuery,
          typeof useIncompleteAssignmentsSheetLazyQuery,
          ExportColumn
        >
          queryHook={useIncompleteAssignmentsSheetQuery}
          lazyQueryHook={useIncompleteAssignmentsSheetLazyQuery}
          getItems={(x) =>
            x?.AdminEmployeesWithIncompleteAssignments.objects ?? []
          }
          getTotalItemCount={(x) =>
            x?.AdminEmployeesWithIncompleteAssignments.totalCount ?? 0
          }
          itemId={(x) => x.id}
          defaultSort={{ column: "name", descending: true }}
          allColumns={["name", "incomplete"]}
          columnTitles={{ name: "User", incomplete: "Incomplete" }}
          rowHeight={52}
          renderFns={{
            name: (x) => (
              <UserNameLocationsRolesTableCell
                userId={x.id.toString()}
                userName={x.name}
                locations={x.locations.map((loc) => loc.name)}
                roles={x.roles.map((role) => role.name)}
              />
            ),
            incomplete: (x) => x.incompleteAssignmentsCount,
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

gql`
  fragment IncompleteAssignmentsSheetEmployee on Employee {
    id
    name
    createdAt
    userType
    locations {
      id
      name
    }
    roles {
      id
      name
    }
    incompleteAssignmentsCount
    pastDueAssignmentsCount
  }
  query IncompleteAssignmentsSheet(
    $pagination: PaginationInput
    $input: AdminEmployeesInput!
  ) {
    AdminEmployeesWithIncompleteAssignments(
      pagination: $pagination
      input: $input
    ) {
      totalCount
      objects {
        ...IncompleteAssignmentsSheetEmployee
      }
    }
  }
`;

export default IncompleteAssignmentsSheet;

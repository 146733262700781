import { Button } from "@src/ui/button";
import Text from "@src/ui/text";
import { Plus } from "lucide-react";
import { FC, ReactNode } from "react";

interface EmptyStateProps {
  title?: string;
  description?: string;
  actionLabel?: string;
  onAction?: () => void;
  illustration: ReactNode;
}

const EmptyWidgetContent: FC<EmptyStateProps> = ({
  title,
  description,
  actionLabel,
  onAction,
  illustration,
}) => {
  return (
    <div className="flex size-full items-center justify-center">
      <div className="flex  max-w-[300px] flex-col items-center text-center">
        <div className="mb-6 flex size-16 items-center justify-center rounded-full bg-muted">
          {illustration}
        </div>
        {title && (
          <Text multiline type="H3" className="mb-2  text-2xl">
            {title}
          </Text>
        )}
        {description && (
          <Text
            multiline
            type="P1"
            className="mb-6 max-w-sm text-muted-foreground"
          >
            {description}
          </Text>
        )}
        {onAction && (
          <Button onClick={onAction} variant={"outline"}>
            <Plus className="mr-2 size-4" />
            {actionLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyWidgetContent;

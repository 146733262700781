import Page, { ActionPermissionString } from "@components/layout/Page";
import useUser from "@hooks/useUser";
import { NextPageWithLayout } from "@pages/_app";
import { UserType } from "@src/types.generated";
import { ReactElement } from "react";
import ManagerHome from "@components/home/ManagerHome";
import AdminHome from "@components/home/AdminHome";
import MyTrainingListPage from "@src/components/home/MyTrainingListPage";

const HomePage: NextPageWithLayout = () => {
  const { user } = useUser();
  if (user?.userType === UserType.Manager) return <ManagerHome />;
  if (user?.userType === UserType.Admin) return <AdminHome />;
  return <MyTrainingListPage />;
};

const requiredActionPermissions: ActionPermissionString[] = [];
const forbiddenUserTypes: UserType[] = [];
HomePage.getLayout = function getLayout(page: ReactElement) {
  return (
    <Page
      requiredActionPermissions={requiredActionPermissions}
      forbiddenUserTypes={forbiddenUserTypes}
    >
      {page}
    </Page>
  );
};

export default HomePage;

import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InactiveUsersSheetQueryVariables = Types.Exact<{
  input: Types.GetPeopleInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type InactiveUsersSheetQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, name: string, lastActivityAt?: string | null, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> }> } };

export type UpdateInactiveUsersSheetMutationVariables = Types.Exact<{
  toDeactivate: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  toKeep: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type UpdateInactiveUsersSheetMutation = { __typename?: 'Mutation', removeEmployees: { __typename?: 'BaseMutationResult', success: boolean }, createStillEmployedConfirmation: { __typename?: 'BaseMutationResult', success: boolean } };

export type InactiveUsersSheetEmployeeFragment = { __typename?: 'Employee', id: number, name: string, lastActivityAt?: string | null, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> };

export const InactiveUsersSheetEmployeeFragmentDoc = gql`
    fragment InactiveUsersSheetEmployee on Employee {
  id
  name
  lastActivityAt
  locations {
    id
    name
  }
  roles {
    id
    name
  }
}
    `;
export const InactiveUsersSheetDocument = gql`
    query InactiveUsersSheet($input: GetPeopleInput!, $pagination: PaginationInput) {
  People(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...InactiveUsersSheetEmployee
    }
  }
}
    ${InactiveUsersSheetEmployeeFragmentDoc}`;

/**
 * __useInactiveUsersSheetQuery__
 *
 * To run a query within a React component, call `useInactiveUsersSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useInactiveUsersSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInactiveUsersSheetQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useInactiveUsersSheetQuery(baseOptions: Apollo.QueryHookOptions<InactiveUsersSheetQuery, InactiveUsersSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InactiveUsersSheetQuery, InactiveUsersSheetQueryVariables>(InactiveUsersSheetDocument, options);
      }
export function useInactiveUsersSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InactiveUsersSheetQuery, InactiveUsersSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InactiveUsersSheetQuery, InactiveUsersSheetQueryVariables>(InactiveUsersSheetDocument, options);
        }
export type InactiveUsersSheetQueryHookResult = ReturnType<typeof useInactiveUsersSheetQuery>;
export type InactiveUsersSheetLazyQueryHookResult = ReturnType<typeof useInactiveUsersSheetLazyQuery>;
export type InactiveUsersSheetQueryResult = Apollo.QueryResult<InactiveUsersSheetQuery, InactiveUsersSheetQueryVariables>;
export const UpdateInactiveUsersSheetDocument = gql`
    mutation UpdateInactiveUsersSheet($toDeactivate: [Int!]!, $toKeep: [Int!]!) {
  removeEmployees(ids: $toDeactivate) {
    success
  }
  createStillEmployedConfirmation(input: {userIds: $toKeep}) {
    success
  }
}
    `;
export type UpdateInactiveUsersSheetMutationFn = Apollo.MutationFunction<UpdateInactiveUsersSheetMutation, UpdateInactiveUsersSheetMutationVariables>;

/**
 * __useUpdateInactiveUsersSheetMutation__
 *
 * To run a mutation, you first call `useUpdateInactiveUsersSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInactiveUsersSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInactiveUsersSheetMutation, { data, loading, error }] = useUpdateInactiveUsersSheetMutation({
 *   variables: {
 *      toDeactivate: // value for 'toDeactivate'
 *      toKeep: // value for 'toKeep'
 *   },
 * });
 */
export function useUpdateInactiveUsersSheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInactiveUsersSheetMutation, UpdateInactiveUsersSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInactiveUsersSheetMutation, UpdateInactiveUsersSheetMutationVariables>(UpdateInactiveUsersSheetDocument, options);
      }
export type UpdateInactiveUsersSheetMutationHookResult = ReturnType<typeof useUpdateInactiveUsersSheetMutation>;
export type UpdateInactiveUsersSheetMutationResult = Apollo.MutationResult<UpdateInactiveUsersSheetMutation>;
export type UpdateInactiveUsersSheetMutationOptions = Apollo.BaseMutationOptions<UpdateInactiveUsersSheetMutation, UpdateInactiveUsersSheetMutationVariables>;